import { memo, useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import { usePostAgeVerificationImages } from '@/apis';
import { meKeys } from '@/apis/queryKeys';
import allImg from '@/assets/lp/ageVerification/all.png';
import cutOffImg from '@/assets/lp/ageVerification/cut-off.png';
import driversLicenseImg from '@/assets/lp/ageVerification/drivers-license.png';
import filledImg from '@/assets/lp/ageVerification/filled.png';
import fogImg from '@/assets/lp/ageVerification/fog.png';
import insuranceCardImg from '@/assets/lp/ageVerification/insurance-card.png';
import kv from '@/assets/lp/ageVerification/kv.png';
import mozaicImg from '@/assets/lp/ageVerification/mozaic.png';
import mynumberCardImg from '@/assets/lp/ageVerification/mynumber-card.png';
import passportImg from '@/assets/lp/ageVerification/passport.png';
import badUserImg from '@/assets/lp/common/bad-user.png';
import cipherImg from '@/assets/lp/common/cipher.png';
import communityImg from '@/assets/lp/common/community.png';
import safetyImg from '@/assets/lp/common/guaranteed-safety.png';
import japhicImg from '@/assets/lp/common/japhic.png';
import policeImg from '@/assets/lp/common/police.png';
import { BirthdayModal } from '@/components/features/modal/BirthdayModal';
import { UploadImgModal } from '@/components/features/modal/UploadImgModal';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { meAtom } from '@/contexts/atoms/me';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useBasicModal, useDisclosure } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/lp/ageVerification.module.scss';
import account from '@/styles/pages/account.module.scss';

type Props = {
  onClose: () => void;
};

export const AgeVerificationLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { age_verification: ageVerification, birthday } = useAtomValue(meAtom);

  const uploadImgModal = useDisclosure();
  const birthdayModal = useDisclosure();
  const { onCloseModal, uploadCompleteModal, commonModal } = useBasicModal();

  const queryClient = useQueryClient();

  const { postAgeVerificationImages } = usePostAgeVerificationImages();

  const handleOpenUploadImgModal = useCallback(() => {
    if (ageVerification.status === 'pass') {
      return;
    }
    if (ageVerification.status === 'inreview') {
      commonModal({
        title: '画像を提出済みです。',
        text: '審査結果はお知らせにてご案内いたします。完了までしばらくお待ちください。'
      });
    } else {
      uploadImgModal.open();
    }
  }, [ageVerification.status]);

  const onCloseUploadModal = useCallback(() => {
    onCloseModal();
    onClose();
  }, []);

  const onUpload = useCallback(async (file: Blob) => {
    try {
      setIsLoading(true);
      await postAgeVerificationImages({ image: file });
      queryClient.invalidateQueries(meKeys.all);

      setIsLoading(false);
      uploadCompleteModal(onCloseUploadModal);
    } catch (err: any) {
      commonModal({ title: err.data.error_message });
    }
  }, []);

  return (
    <>
      <TrackPageView viewName='lp_age_verification' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>年齢確認</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.kv}>
              <img src={kv} alt='安心してメッセージをご利用いただくために年齢確認' />
            </div>

            <div className={styles.inner}>
              <div className={styles['section-block']}>
                <h2 className={styles['section-title']}>年齢確認が必要な理由</h2>
                <p className={components['text-small']}>
                  みなさまに安心してご利用いただくために、paddyでは年齢確認を必須としています。
                </p>
                <ul className={styles['row-imgs']}>
                  <li>
                    <img src={safetyImg} alt='安全性の担保' />
                    <h3 className={styles['row-imgs-label']}>安全性の担保</h3>
                  </li>
                  <li>
                    <img src={communityImg} alt='コミュニティの品質向上' />
                    <h3 className={styles['row-imgs-label']}>
                      コミュニティ
                      <br />
                      の品質向上
                    </h3>
                  </li>
                  <li>
                    <img src={badUserImg} alt='不適切なユーザー排除' />
                    <h3 className={styles['row-imgs-label']}>
                      不適切な
                      <br />
                      ユーザー排除
                    </h3>
                  </li>
                </ul>
              </div>

              <div className={styles['section-block']}>
                <h2 className={styles['section-title']}>提出可能な書類</h2>
                <ul className={styles['wrap-imgs']}>
                  <li>
                    <img src={driversLicenseImg} alt='運転免許証' />
                    <h3 className={styles['wrap-imgs-label']}>運転免許証</h3>
                  </li>
                  <li>
                    <img src={passportImg} alt='パスポート' />
                    <h3 className={styles['wrap-imgs-label']}>パスポート</h3>
                  </li>
                  <li>
                    <img src={insuranceCardImg} alt='健康保険証' />
                    <h3 className={styles['wrap-imgs-label']}>健康保険証</h3>
                  </li>
                  <li>
                    <img src={mynumberCardImg} alt='マイナンバーカード' />
                    <h3 className={styles['wrap-imgs-label']}>マイナンバーカード</h3>
                  </li>
                </ul>
                <p className={styles.annotation}>
                  その他提出可能な書類に関しては
                  <br />
                  <a
                    className={styles.link}
                    href='https://paddy-media.com/manual/400/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    こちら
                  </a>
                  をご確認ください
                </p>
              </div>

              <div className={styles['section-block']}>
                <h2 className={styles['section-title']}>
                  みなさまの情報を
                  <br />
                  厳重管理しています
                </h2>
                <p className={components['text-small']}>
                  お客様の個人情報は、個人情報保護法に基づき、厳正な管理・取り扱いを行います。ご提出書類は運営事務局にて審査の後、速やかに破棄いたします。
                </p>
                <ul className={styles['row-imgs']}>
                  <li>
                    <img src={cipherImg} alt='通信を暗号化' />
                    <h3 className={styles['row-imgs-label']}>通信を暗号化</h3>
                  </li>
                  <li>
                    <img src={japhicImg} alt='JAPHICマーク取得' />
                    <h3 className={styles['row-imgs-label']}>
                      JAPHIC
                      <br />
                      マーク取得
                    </h3>
                  </li>
                  <li>
                    <img src={policeImg} alt='警視庁への届出済' />
                    <h3 className={styles['row-imgs-label']}>
                      警視庁への
                      <br />
                      届出済
                    </h3>
                  </li>
                </ul>
              </div>

              <div className={styles['section-block']}>
                <h2 className={styles['section-title']}>年齢確認の流れ</h2>

                <div className={styles.step}>
                  <span className={styles['step-label']}>STEP 1</span>
                  <h3 className={styles['step-title']}>書類を撮影</h3>
                  <p>
                    <span className={styles['red-text']}>書類全体がハッキリと映るように</span>
                    撮影をしてください。
                  </p>
                  <div className={styles['step-annotation']}>
                    <p>全体がみえるように撮影</p>
                  </div>
                  <div className={styles['step-img']}>
                    <img src={allImg} alt='証明書' />
                  </div>
                </div>

                <div className={styles['caution-block']} data-narrow>
                  <h3 className={styles['caution-heading']}>提出時の注意点</h3>
                  <p className={clsx(components['text-small'], components['align-center'])}>
                    以下のような画像は承認されません。
                  </p>
                  <ul className={styles['row-imgs']}>
                    <li>
                      <img src={filledImg} alt='塗りつぶしで見えない' />
                      <h4 className={styles['row-imgs-label']}>
                        塗りつぶしで
                        <br />
                        見えない
                      </h4>
                    </li>
                    <li>
                      <img src={fogImg} alt='ぼやけて見えない' />
                      <h4 className={styles['row-imgs-label']}>
                        ぼやけて
                        <br />
                        見えない
                      </h4>
                    </li>
                    <li>
                      <img src={cutOffImg} alt='隠れて見えない' />
                      <h4 className={styles['row-imgs-label']}>
                        隠れて
                        <br />
                        見えない
                      </h4>
                    </li>
                  </ul>
                  <p style={{ marginTop: '32px', marginBottom: '24px' }}>
                    健康保険証のご提出の場合は、必ず「
                    <span className={styles['red-text']}>記号</span>
                    」「
                    <span className={styles['red-text']}>番号</span>
                    」「
                    <span className={styles['red-text']}>枝番号</span>
                    」「
                    <span className={styles['red-text']}>保険者番号</span>
                    」「
                    <span className={styles['red-text']}>QRコード</span>
                    」が見えないように隠してください。
                  </p>
                  <div className={styles['section-img']}>
                    <img src={mozaicImg} alt='健康保険証' />
                  </div>
                </div>

                <div className={styles.step}>
                  <span className={styles['step-label']}>STEP 2</span>
                  <h3 className={styles['step-title']}>運営事務局で審査</h3>
                  <p>ご提出書類は運営事務局にて順次審査をいたします。審査後、お知らせにて審査結果の通知が届きます。</p>
                </div>

                <div className={styles['caution-block']} data-colored>
                  <h3 className={styles['caution-heading']}>書類提出の前に</h3>
                  <p className={components['text-small']}>
                    登録している生年月日と提出書類の生年月日が一致しない場合は、審査で承認されませんので、ご注意ください。
                  </p>
                </div>

                <button type='button' onClick={birthdayModal.open} className={styles['birthday-wrapper']}>
                  <span className={styles['birthday-label']}>現在登録している生年月日</span>
                  <span className={styles['birthday-value']}>
                    {birthday
                      .split('-')
                      .map((part) => part.replace(/^0+/, ''))
                      .join('/')}
                  </span>
                </button>

                {isLoading ? (
                  <button
                    type='button'
                    className={components.button}
                    data-loading={isLoading}
                    style={{ backgroundColor: '#004285' }}
                  >
                    <LoadingSpinner size={16} />
                  </button>
                ) : (
                  <>
                    <button type='button' className={components.button} onClick={handleOpenUploadImgModal}>
                      年齢確認をはじめる
                    </button>
                    <p className={styles['camera-text']}>
                      ※カメラが上手く起動しない場合は、ご利用ブラウザのカメラアクセスがOFFになっている可能性があります。詳しくはブラウザのアクセス設定をご確認ください。
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>

        <UploadImgModal isOpen={uploadImgModal.isOpen} onClose={uploadImgModal.close} onUpload={onUpload} />

        <BirthdayModal isOpen={birthdayModal.isOpen} onClose={birthdayModal.close} birthDay={birthday} />
      </div>
    </>
  );
});
