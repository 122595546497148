import { Fragment, ReactNode, memo, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import point6_1Img from '@/assets/lp/common/richlike_step1.png';
import point6_2Img from '@/assets/lp/common/richlike_step2.png';
import banner from '@/assets/lp/Premium/banner.png';
import point1Img from '@/assets/lp/Premium/card1.png';
import point2Img from '@/assets/lp/Premium/card2.png';
import point3Img from '@/assets/lp/Premium/card3.png';
import point4Img from '@/assets/lp/Premium/card4.png';
import point5Img from '@/assets/lp/Premium/card5.png';
import kv from '@/assets/lp/Premium/kv.png';
import { meAtom } from '@/contexts/atoms/me';
import { basePath } from '@/functions/constants/common';
import { slideVariants } from '@/functions/constants/framerMotion';
import styles from '@/styles/features/lp/premium.module.scss';
import account from '@/styles/pages/account.module.scss';

const PlanList: React.FC = () => {
  const { id } = useAtomValue(meAtom);

  const handleClickPlan = useCallback((month: number) => {
    window.location.href = `${basePath}/stripe/premium_subscriptions/${month}?user_id=${id}&new_app=true`;
  }, []);

  return (
    <div className={styles['plan-block']}>
      <h2 className={styles['plan-heading']}>プランを選択</h2>
      <ul className={styles['plan-list']}>
        <li className={styles['plan-item']}>
          <span className={styles['plan-item-label']}>まずはお試し</span>
          <button type='button' onClick={() => handleClickPlan(1)} className={styles['plan-item-content']}>
            <h3 className={styles['plan-item-heading']}>
              <span>1</span>ヶ月プラン
            </h3>
            <p className={styles['plan-item-price']}>
              <span className={styles['plan-item-price-num']}>12,000</span>
              <span className={styles['plan-item-price-unit']}>円(税込)</span>
            </p>
            <div className={styles['plan-item-point']}>
              <p className={styles['plan-item-point-heading']}>ポイント付与</p>
              <p className={styles['plan-item-point-detail']}>
                毎月<span className={styles['plan-item-point-detail-num']}>50</span>PT
              </p>
            </div>
          </button>
        </li>
        <li className={styles['plan-item']} data-recommend>
          <span className={styles['plan-item-label']}>人気No.1</span>
          <button type='button' onClick={() => handleClickPlan(3)} className={styles['plan-item-content']}>
            <h3 className={styles['plan-item-heading']}>
              <span>3</span>ヶ月プラン
            </h3>
            <p className={styles['plan-item-price']}>
              <span className={styles['plan-item-price-num']}>29,800</span>
              <span className={styles['plan-item-price-unit']}>円(税込)</span>
            </p>
            <p className={styles['plan-item-discount-price']}>
              <span>6,200</span>円お得
            </p>
            <div className={styles['plan-item-point']}>
              <p className={styles['plan-item-point-heading']}>ポイント付与</p>
              <p className={styles['plan-item-point-detail']}>
                毎月<span className={styles['plan-item-point-detail-num']}>50</span>PT
              </p>
              <p className={styles['plan-item-point-bonus']}>+ボーナス80PT</p>
            </div>
          </button>
        </li>
        <li className={styles['plan-item']}>
          <span className={styles['plan-item-label']}>おすすめ！</span>
          <button type='button' onClick={() => handleClickPlan(6)} className={styles['plan-item-content']}>
            <h3 className={styles['plan-item-heading']}>
              <span>6</span>ヶ月プラン
            </h3>
            <p className={styles['plan-item-price']}>
              <span className={styles['plan-item-price-num']}>54,800</span>
              <span className={styles['plan-item-price-unit']}>円(税込)</span>
            </p>
            <p className={styles['plan-item-discount-price']}>
              <span>17,200</span>円お得
            </p>
            <div className={styles['plan-item-point']}>
              <p className={styles['plan-item-point-heading']}>ポイント付与</p>
              <p className={styles['plan-item-point-detail']}>
                毎月<span className={styles['plan-item-point-detail-num']}>50</span>PT
              </p>
              <p className={styles['plan-item-point-bonus']}>+ボーナス200PT</p>
            </div>
          </button>
        </li>
        <li className={styles['plan-item']}>
          <span className={styles['plan-item-label']}>1番お得！</span>
          <button type='button' onClick={() => handleClickPlan(12)} className={styles['plan-item-content']}>
            <h3 className={styles['plan-item-heading']}>
              <span>12</span>ヶ月プラン
            </h3>
            <p className={styles['plan-item-price']}>
              <span className={styles['plan-item-price-num']}>84,800</span>
              <span className={styles['plan-item-price-unit']}>円(税込)</span>
            </p>
            <p className={styles['plan-item-discount-price']}>
              <span>59,200</span>円お得
            </p>
            <div className={styles['plan-item-point']}>
              <p className={styles['plan-item-point-heading']}>ポイント付与</p>
              <p className={styles['plan-item-point-detail']}>
                毎月<span className={styles['plan-item-point-detail-num']}>50</span>PT
              </p>
              <p className={styles['plan-item-point-bonus']}>+ボーナス500PT</p>
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
};

type FeatureProps = {
  index?: number;
  title: string;
  children: ReactNode;
};

const featureItems: FeatureProps[] = [
  {
    title: '女性とメッセージし放題',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>有料会員登録すると、お相手とのやりとりが好きなだけ可能に。</p>
          <p>お相手について知るためにも、気になる方にどんどんメッセージを送りましょう！</p>
        </div>
        <div className={styles['feature-sub-text']}>
          <p>*お相手とマッチすることでメッセージができるようになります。</p>
          <p>*マッチにはポイント消費するいいね!が必要です。</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={point1Img} alt='女性とメッセージし放題' />
        </div>
      </>
    )
  },
  {
    title: '好みの条件でデートの\n募集ができる',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>
            「今すぐ会いたい」「この日に会いたい」など、
            <span className={styles['accent-text']}>希望のシーンや条件で</span>
            会えるお相手を探したり・募集することができます。
          </p>
          <p>希望にマッチしたお相手がきっと見つかるはず！</p>
        </div>
        <div className={styles['feature-sub-text']}>
          <p>*募集の投稿にはポイントが必要です。</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={point2Img} alt='好みの条件でデートの募集ができる' />
        </div>
      </>
    )
  },
  {
    title: 'プランに応じたポイントを\n毎月プレゼント',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>いいね!を送ったり、募集を投稿したり、クオリティチェックでお相手の近況を確認したり。</p>
          <p>さまざまな機能でご利用いただけるポイントを毎月プレゼント！</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={point3Img} alt='プランに応じたポイントを毎月プレゼント' />
        </div>
      </>
    )
  },
  {
    title: 'お相手のいいね!数がみれる',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>お相手が受け取っているいいね!数が、プロフィールで確認できるように。</p>
          <p>人気のお相手が一目で分かります！</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={point4Img} alt='お相手のいいね!数がみれる' />
        </div>
      </>
    )
  },
  {
    title: 'お相手の画面であなたを\n上位に掲載',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>有料会員になると、あなたがお相手の画面で優先表示されるようになります。</p>
          <p>上位掲載されることで、マッチ率も大幅に増加！</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={point5Img} alt='お相手の画面であなたを上位に掲載' />
        </div>
      </>
    )
  },
  {
    title: '継続期間に応じて\n「リッチいいね!」を付与',
    children: (
      <>
        <div className={styles['feature-text']}>
          <p>有料会員には継続期間に応じて「リッチいいね!」をプレゼント。</p>
          <p>通常の「いいね!」より目に留まりやすいので、よりアピールしたい方に送るのがおすすめ！</p>
        </div>
        <div className={styles['feature-img']}>
          <img src={point6_1Img} alt='継続期間に応じて「リッチいいね!」を付与' />
        </div>
        <p className={styles['feature-center-text']}>さらに！</p>
        <div className={styles['feature-img']} data-narrow>
          <img src={point6_2Img} alt='10ヶ月目以降毎月獲得' />
        </div>
        <p className={styles['annotation-text']}>※有料会員の継続が途切れると、付与サイクルがリセットされます。</p>
      </>
    )
  }
];

const Feature: React.FC<FeatureProps> = (props) => {
  const { index, title, children } = props;

  return (
    <li className={styles.feature}>
      <div className={styles['feature-header']}>
        <span className={styles['feature-header-label']}>POINT {index}</span>
        <h3 className={styles['feature-title']}>{title}</h3>
      </div>
      <div className={styles['feature-content']}>{children}</div>
    </li>
  );
};

const Notes: React.FC = () => (
  <div className={styles.notes}>
    <h2 className={styles['notes-heading']}>注意事項</h2>
    <p className={styles['notes-text']}>・複数月プランのお支払いは一括払いとなります。</p>
    <p className={styles['notes-text']}>・ボーナスポイントは毎月分割で付与されます。</p>
    <p className={styles['notes-text']}>
      ・クレジットカードのご利用明細には「paddy」ではなく「PDDAY」「ピーディーペイ」のいずれかが表示されます。
    </p>
    <p className={styles['notes-text']}>
      ・ご購入月によるサービス提供日数のずれを防ぐため、いずれのプランも1ヶ月を30日としています。
    </p>
    <p className={styles['notes-text']}>・自動継続課金は期限が切れる24時間以内に自動的に更新される仕組みです。</p>
    <p className={styles['notes-text']}>・継続利用を希望されない場合は、ご自身でのご解約が必要となります。</p>
    <p className={styles['notes-text']}>・ご解約後も期限を過ぎるまで、有料会員としてサービスをご利用いただけます。</p>
    <p className={styles['notes-text']}>・サービス性質上、月割り・日割りでのご返金は行なっておりません。</p>
    <p className={styles['notes-text']}>・ご解約の手順は「設定」＞「購読の解約」をご確認ください。</p>
    <p className={styles['notes-text']}>・いいね!を送るにはポイントが必要です。</p>
    <div className={styles['notes-link']}>
      <a href='https://www.paddy67.today/terms' target='_blank' rel='noreferrer'>
        利用規約
      </a>
      <a href='https://www.paddy67.today/privacy_policy' target='_blank' rel='noreferrer'>
        プライバシーポリシー
      </a>
    </div>
  </div>
);

type Props = {
  onClose: () => void;
};

export const PremiumLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  return (
    <>
      <TrackPageView viewName='lp_premium' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>有料会員登録</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.banner}>
              <img src={banner} alt='アプリより10%OFF' />
            </div>
            <div className={styles.kv}>
              <img src={kv} alt='女性と出会えている有料会員の割合91.4%' />
            </div>

            <PlanList />

            <div className={styles['feature-block']}>
              <div className={styles['feature-inner']}>
                <h2 className={styles['feature-heading']}>
                  有料会員になると使える
                  <br />
                  さまざまな機能
                </h2>
                <ul className={styles['feature-list']}>
                  {featureItems.map((feature, index) => (
                    <Fragment key={feature.title}>
                      <Feature {...feature} index={index + 1} />
                    </Fragment>
                  ))}
                </ul>
              </div>
            </div>

            <PlanList />

            <Notes />
          </div>
        </motion.div>
      </div>
    </>
  );
});
