import { ChangeEvent, memo, useCallback, useRef, useState } from 'react';
import { useDeleteMovies, usePostMovies, usePutDiscloseMovies } from '@/apis';
import Camera from '@/assets/icons/camera.svg';
import Photo from '@/assets/icons/photo.svg';
import { AsyncButton } from '@/components/features/AsyncButton';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';
import { useDisclosure, useSnackbar } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';

const discloseOptions = {
  public: '全体に公開',
  like: 'マッチ済みのお相手のみ公開'
};

export type DiscloseType = 'public' | 'like';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => Promise<void>;
  movieId: number | null;
  disclose: DiscloseType | null;
};

export const AddMovieModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, refetch, movieId, disclose } = props;

  const [temporarily, setTemporarily] = useState<DiscloseType>(disclose ?? 'public');

  const cameraRollInput = useRef<HTMLInputElement>(null);
  const cameraAppInput = useRef<HTMLInputElement>(null);

  const { openSnackbar } = useSnackbar();
  const discloseModal = useDisclosure();

  const { postMovies } = usePostMovies();
  const { putDiscloseMovies } = usePutDiscloseMovies();
  const { deleteMovies } = useDeleteMovies();

  const handleClickCameraRoll = useCallback(() => {
    if (!cameraRollInput.current) return;
    cameraRollInput.current.click();
  }, []);

  const handleClickCamera = useCallback(() => {
    if (!cameraAppInput.current) return;
    cameraAppInput.current.click();
  }, []);

  const getVideoDuration = useCallback(async (file: File): Promise<number> => {
    return new Promise((res) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        res(video.duration);
      };

      video.src = URL.createObjectURL(file);
    });
  }, []);

  const getVideoSize = useCallback((file: File) => {
    const { size } = file;

    return size / (1024 * 1024);
  }, []);

  const onChangeMovie = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files[0]) {
      onClose();

      const file = files[0];
      const duration = await getVideoDuration(file);
      const MB = getVideoSize(file);

      if (duration < 5) {
        openSnackbar({
          type: 'popup',
          title: '5秒未満の動画はアップロードできません。',
          text: '動画の長さを調整した上で、再度お試しください。',
          level: 'error'
        });
      } else if (duration > 30) {
        openSnackbar({
          type: 'popup',
          title: '30秒を超える動画はアップロードできません。',
          text: '動画の長さを調整した上で、再度お試しください。',
          level: 'error'
        });
      } else if (MB > 50) {
        openSnackbar({
          type: 'popup',
          title: '50MBを超える動画はアップロードできません。',
          text: '動画の長さを調整した上で、再度お試しください。',
          level: 'error'
        });
      } else {
        await postMovies({ video: files[0] });

        openSnackbar({
          type: 'popup',
          title: 'アップロードを処理しています。',
          text: 'アップロード後の審査が完了次第公開されます。'
        });
      }

      refetch();
    }
  }, []);

  const handleClickDisclose = useCallback(async () => {
    await putDiscloseMovies({ movieId: movieId!, body: { disclose: temporarily } });
    await refetch();

    openSnackbar({
      type: 'toast',
      text: '公開範囲を変更しました。'
    });
    discloseModal.close();
    onClose();
  }, [movieId, temporarily]);

  const handleClickDelete = useCallback(async () => {
    await deleteMovies({ movieId: movieId! });
    await refetch();
    openSnackbar({
      type: 'toast',
      text: '動画を削除しました。'
    });

    onClose();
  }, [movieId]);

  return (
    <>
      <HalfModal id='addMovieModal' isOpen={isOpen} onClose={onClose} title={movieId ? '編集' : '動画を選ぶ'} isInner>
        <div className={components['gap-wrapper']}>
          <button type='button' className={components.button} data-color='white' onClick={handleClickCameraRoll}>
            <input
              id='cameraRoll'
              ref={cameraRollInput}
              type='file'
              accept='video/*'
              onChange={onChangeMovie}
              style={{ display: 'none' }}
            />
            <img className={components['button-icon']} src={Photo} alt='' width={20} height={20} />
            カメラロールから選択
          </button>
          <button type='button' className={components.button} data-color='white' onClick={handleClickCamera}>
            <input
              id='camera'
              ref={cameraAppInput}
              type='file'
              capture='user'
              accept='video/*'
              onChange={onChangeMovie}
              style={{ display: 'none' }}
            />
            <img className={components['button-icon']} src={Camera} alt='' width={20} height={20} />
            動画を撮影
          </button>
          {movieId && (
            <button type='button' className={components.button} data-color='white' onClick={discloseModal.open}>
              公開範囲を変更
            </button>
          )}
          {movieId && (
            <AsyncButton className={components.button} data-color='white' onClick={handleClickDelete}>
              <span className={components['red-text']}>削除</span>
            </AsyncButton>
          )}
          <button type='button' className={components.button} data-color='clear' onClick={onClose}>
            <span className={components['black-text']}>キャンセル</span>
          </button>
        </div>
      </HalfModal>

      <HalfModal
        id='discloseModal'
        isOpen={discloseModal.isOpen}
        onClose={discloseModal.close}
        title='公開範囲を変更'
        footer={
          <AsyncButton className={components.button} data-color='black' onClick={handleClickDisclose}>
            決定
          </AsyncButton>
        }
      >
        <RadioGroup
          selected={temporarily}
          options={discloseOptions}
          onChange={(e) => setTemporarily(e.target.value as DiscloseType)}
        />
      </HalfModal>
    </>
  );
});
