import { memo, useCallback, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { TrackPageView } from '@/analytics/TrackPageView';
import { usePutPhone, usePutPhoneAuth } from '@/apis';
import { AsyncButton } from '@/components/features/AsyncButton';
import { BasicPanel } from '@/components/styles/projects/BasicPanel';
import { FullModal } from '@/components/styles/uis/FullModal';
import { OtpInput } from '@/components/styles/uis/OtpInput';
import { TextInput } from '@/components/styles/uis/TextInput';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { otpLength } from '@/functions/constants/common';
import { LOCAL_STORAGE } from '@/functions/constants/enums';
import { slideVariants } from '@/functions/constants/framerMotion';
import { maskValue } from '@/functions/helpers';
import { phoneSchema } from '@/functions/schemas/common';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/phoneVerifyModal.module.scss';

const schema = z.object({
  phone: phoneSchema
});

type Schema = z.infer<typeof schema>;

type Props = {
  isOpen: boolean;
  onClose: (complete?: boolean) => void;
};

export const PhoneVerifyModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose } = props;
  const [step, setStep] = useState(1);
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState(false);

  const [isIntervalSms, setIsIntervalSms] = useState<boolean>(true);
  const [intervalSmsText, setIntervalSmsText] = useState<string>('');

  const { phone } = useAtomValue(meAtom);
  const { isPhoneVerify } = useAtomValue(meFlagAtom);

  const { putPhone } = usePutPhone();
  const { putPhoneAuth } = usePutPhoneAuth();

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = useForm<Schema>({
    mode: 'onChange',
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (isOpen) {
      // コンポーネントがレンダリングされたタイミングで 値が入らないことがあるためフォームをリセット
      reset({ phone: phone ?? '' });
      setIsIntervalSms(true);
      setIntervalSmsText('');
    }
  }, [isOpen]);

  const onSubmit: SubmitHandler<Schema> = (formData) => {
    const smsSentAt = localStorage.getItem(LOCAL_STORAGE.SMS_CODE_SENT_AT.KEY);

    // 15秒以内は次へボタン無効
    if (smsSentAt !== null && Date.now() - Number(smsSentAt) < 15000) {
      setIsIntervalSms(false);
      setIntervalSmsText('試行回数が上限に達しました。時間をおいて再度お試しください。');
    } else {
      setIsIntervalSms(true);
      setIntervalSmsText('');
      putPhone(formData);
      setStep(2);
      localStorage.setItem(LOCAL_STORAGE.SMS_CODE_SENT_AT.KEY, Date.now().toString());
    }
  };

  const handleClickVerify = useCallback(async () => {
    try {
      await putPhoneAuth({ token: code });
      onClose(true);
      setCode('');
      setStep(1);
    } catch {
      setCodeError(true);
    }
  }, [code]);

  const handleClickReEnter = useCallback(() => {
    setCode('');
    setStep(1);
  }, []);

  return (
    <FullModal id='phoneVerifyModal' isOpen={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <button
            type='button'
            className={styles['close-button']}
            onClick={step === 1 ? () => onClose(false) : handleClickReEnter}
            aria-label='close'
            data-prev={step !== 1}
          />
          <p>{isPhoneVerify ? '電話番号を変更' : '電話番号認証'}</p>
        </div>
        <AnimatePresence mode='wait' initial={false}>
          {step === 1 && (
            <>
              <TrackPageView viewName='modal_phone_input' />

              <motion.div key='step1' initial='left' animate='enter' exit='left' variants={slideVariants}>
                <div className={styles['heading-wrapper']}>
                  <h2 className={components['heading-1']}>電話番号を入力してください。</h2>
                </div>
                <BasicPanel height={158}>
                  <div className={components['gap-wrapper']}>
                    <TextInput
                      id='phone'
                      value={watch('phone')}
                      register={register('phone')}
                      placeholder='電話番号'
                      annotation={errors.phone?.message ?? intervalSmsText}
                      isInvalid={'phone' in { ...errors } || !isIntervalSms}
                      inputMode='tel'
                    />
                    <AsyncButton
                      className={components.button}
                      onClick={handleSubmit(onSubmit)}
                      disabled={!isValid || !isDirty}
                      data-disabled={!isValid || !isDirty}
                    >
                      次へ
                    </AsyncButton>
                  </div>
                </BasicPanel>
              </motion.div>
            </>
          )}

          {step === 2 && (
            <>
              <TrackPageView viewName='modal_phone_code_input' />

              <motion.div key='step2' initial='right' animate='enter' exit='right' variants={slideVariants}>
                <div className={styles['heading-wrapper']}>
                  <h2 className={components['heading-1']}>認証コードを入力してください。</h2>
                  <p className={components['basic-text']}>
                    {maskValue(watch('phone'))}宛に届いた認証コードを入力してください。
                  </p>
                </div>
                <BasicPanel height={200}>
                  <div className={styles['code-gap-wrapper']}>
                    <OtpInput value={code} inputLength={otpLength} onChange={setCode} isInvalid={codeError} />
                    <AsyncButton
                      className={components.button}
                      onClick={handleClickVerify}
                      disabled={code.length !== otpLength}
                      data-disabled={code.length !== otpLength}
                    >
                      認証
                    </AsyncButton>
                    <div className={components['space-around-wrapper']}>
                      <button type='button' className={components.link} onClick={handleClickReEnter}>
                        コードを再送
                      </button>
                      <button type='button' className={components.link} onClick={handleClickReEnter}>
                        電話番号を再入力
                      </button>
                    </div>
                  </div>
                </BasicPanel>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </FullModal>
  );
});
