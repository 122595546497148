import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { AsyncButton } from '@/components/features/AsyncButton';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/trimmingModal.module.scss';
import 'react-image-crop/dist/ReactCrop.css';

const baseWidth = 150;

type Props = {
  isSquare: boolean;
  imgSrc: string;
  onClose: () => void;
  onTrimming: (img: Blob) => Promise<void>;
};

export const TrimmingModal: React.FC<Props> = memo((props) => {
  const { isSquare, imgSrc, onClose, onTrimming } = props;

  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: baseWidth,
    height: isSquare ? baseWidth : baseWidth / 2
  });
  const imgRef = useRef<HTMLImageElement>(null);
  const [isHorizontally, setIsHorizontally] = useState(true);

  const handleClickTrimming = useCallback(async () => {
    if (!imgRef.current) return;

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    const canvas = document.createElement('canvas');
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    canvas.toBlob(async (blob) => {
      if (blob) {
        await onTrimming(blob);
      }
    }, 'image/webp');

    onClose();
  }, [imgSrc, crop]);

  const getImageDimensions = useCallback(
    (imgSrc: string, callback: (dimensions: { width: number; height: number } | null) => void) => {
      const img = new Image();

      img.onload = () => {
        const { width } = img;
        const { height } = img;
        callback({ width, height });
      };

      img.onerror = () => {
        callback(null);
      };

      img.src = imgSrc;
    },
    [imgSrc]
  );

  useEffect(() => {
    getImageDimensions(imgSrc, (dimensions) => {
      if (dimensions) {
        const maxWidth = window.innerWidth > 393 ? 393 : window.innerWidth; // 最大幅
        const maxHeight = window.innerHeight - 237; // 最大高さ
        const { width } = dimensions;
        const { height } = dimensions;

        setIsHorizontally(width / maxWidth > height / maxHeight);
      }
    });
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <button type='button' className={components['back-link-button']} onClick={onClose} aria-label='close' />
      </div>
      <div className={styles.content} data-ishorizontally={isHorizontally}>
        <ReactCrop crop={crop} onChange={(crop) => setCrop(crop)} aspect={isSquare ? 1 : 2 / 1}>
          <img ref={imgRef} src={imgSrc} alt='' />
        </ReactCrop>
      </div>
      <div className={styles.footer}>
        <AsyncButton className={components.button} data-color='black' onClick={handleClickTrimming}>
          トリミング
        </AsyncButton>
      </div>
    </div>
  );
});
