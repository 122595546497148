import { memo } from 'react';
import { motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import sectionImg6 from '@/assets/lp/common/bad-user.png';
import sectionImg1 from '@/assets/lp/common/cipher.png';
import sectionImg5 from '@/assets/lp/common/community.png';
import sectionImg4 from '@/assets/lp/common/guaranteed-safety.png';
import sectionImg2 from '@/assets/lp/common/japhic.png';
import sectionImg3 from '@/assets/lp/common/police.png';
import report from '@/assets/lp/common/report.png';
import safety from '@/assets/lp/common/safety.png';
import kv from '@/assets/lp/safety/kv.png';
import sectionImg7 from '@/assets/lp/safety/section_03.png';
import sectionImg8 from '@/assets/lp/safety/section_04.png';
import sectionImg9 from '@/assets/lp/safety/section_05.png';
import sectionImg10 from '@/assets/lp/safety/section_06.png';
import sectionImg11 from '@/assets/lp/safety/section_07.png';
import { slideVariants } from '@/functions/constants/framerMotion';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/lp/safety.module.scss';
import account from '@/styles/pages/account.module.scss';

type Props = {
  onClose: () => void;
};

export const SafetyLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  return (
    <>
      <TrackPageView viewName='lp_safety_initiatives' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>安心・安全の取り組み</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.inner}>
              <div className={styles.kv}>
                <img src={kv} alt='安心・安全のための7つの取り組み' />
              </div>
              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <span className={styles['section-label']}>01</span>
                  <h2 className={styles['section-title']}>個人情報保護の徹底</h2>
                </div>
                <p className={components['basic-text']}>
                  お客様の個人情報は、個人情報保護法に基づき、厳正な管理・取り扱いを行います。ご提出書類は運営事務局にて審査の後、速やかに破棄いたします。
                </p>
                <div className={styles['section-img']}>
                  <ul className={styles['row-imgs']}>
                    <li>
                      <img src={sectionImg1} alt='通信を暗号化' />
                      <p className={styles['row-imgs-label']}>通信を暗号化</p>
                    </li>
                    <li>
                      <img src={sectionImg2} alt='JAPHICマーク取得' />
                      <p className={styles['row-imgs-label']}>
                        JAPHIC
                        <br />
                        マーク取得
                      </p>
                    </li>
                    <li>
                      <img src={sectionImg3} alt='警視庁への届出済' />
                      <p className={styles['row-imgs-label']}>
                        警視庁への
                        <br />
                        届出済
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <span className={styles['section-label']}>02</span>
                  <h2 className={styles['section-title']}>年齢確認による安全担保</h2>
                </div>
                <p className={components['basic-text']}>
                  メッセージ機能のご利用には、公的証明書による年齢確認が必須です。不適切ユーザーや未成年の使用を禁止しているため、安心してご利用いただけます。
                </p>
                <div className={styles['section-img']}>
                  <ul className={styles['row-imgs']}>
                    <li>
                      <img src={sectionImg4} alt='安全性の担保' />
                      <p className={styles['row-imgs-label']}>安全性の担保</p>
                    </li>
                    <li>
                      <img src={sectionImg5} alt='コミュニティの品質向上' />
                      <p className={styles['row-imgs-label']}>
                        コミュニティ
                        <br />
                        の品質向上
                      </p>
                    </li>
                    <li>
                      <img src={sectionImg6} alt='不適切なユーザー排除' />
                      <p className={styles['row-imgs-label']}>
                        不適切な
                        <br />
                        ユーザー排除
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <span className={styles['section-label']}>03</span>
                  <h2 className={styles['section-title']}>不適切なユーザーの監視・排除</h2>
                </div>
                <p className={components['basic-text']}>
                  運営事務局では不適切なユーザーの厳重監視を実施しています。また、勧誘などの規約違反にあたる行動をとるユーザーに対し、警告や強制退会等の措置を行っています。
                </p>
                <div className={styles['section-img']}>
                  <img src={sectionImg7} alt='不適切なユーザーの監視・排除' />
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <span className={styles['section-label']}>04</span>
                  <h2 className={styles['section-title']}>トラブルのリスクを示す注意マーク</h2>
                </div>
                <p className={components['basic-text']}>
                  悪質なマナー違反があったと判断されたユーザーには、注意マークが一定期間付与されます。注意マークはプロフィールに表示されるようになります。
                </p>
                <div className={styles['section-img']}>
                  <img src={sectionImg8} alt='トラブルのリスクを示す注意マーク' />
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <span className={styles['section-label']}>05</span>
                  <h2 className={styles['section-title']}>バレずにpaddyを使えるシークレットモード</h2>
                </div>
                <p className={components['basic-text']}>
                  あなたが「いいね!」をしたお相手とマッチング中のお相手以外、あなたのプロフィールを閲覧することできなくなります。
                </p>
                <div className={styles['section-img']}>
                  <img src={sectionImg9} alt='バレずにpaddyを使えるシークレットモード' />
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <span className={styles['section-label']}>06</span>
                  <h2 className={styles['section-title']}>さくらゼロ</h2>
                </div>
                <p className={components['basic-text']}>
                  paddyには運営事務局が用意した会員（さくら）はいません。また、業者などの不正ユーザーの排除も徹底して実施しています。
                </p>
                <div className={styles['section-img']}>
                  <img src={sectionImg10} alt='さくらゼロ' />
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <span className={styles['section-label']}>07</span>
                  <h2 className={styles['section-title']}>運営による通報の精査</h2>
                </div>
                <p className={components['basic-text']}>
                  いただいた通報は、運営事務局で1件ずつ精査を実施しています。事実確認の上で、必要と判断された場合は、注意喚起や強制退会等の措置を行っております。
                </p>
                <div className={styles['section-img']}>
                  <img src={sectionImg11} alt='運営による通報の精査' />
                </div>
              </div>

              <div className={styles['other-imgs']}>
                <img src={safety} alt='paddy安心・安全の取り組み' />
                <img
                  src={report}
                  alt='paddyでは、安心安全なサービス運営を心がけていますが、以下のようなユーザーを見かけた際は運営事務局へお知らせください。'
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
