import { GENDER } from '@/functions/constants/enums';
import { Me } from '@/functions/types/me';

type ViewEvent = {
  event: 'pageView';
  viewName: string;
};

/**
 * 要素表示イベント
 */
type InViewEvent = {
  event: 'inView';
  in_view_label: string;
};

/**
 * クリックイベント
 */
type ClickEvent = {
  event: 'click';
  click_label: string;
};

/**
 * ユーザー識別イベント
 */
type IdentifyEvent = {
  event: 'identify';
  user_id: string;
  email: string;
  phone: string;
  name: string;
};

/**
 * ユーザー属性イベント
 */
type AttributeEvent = {
  event: 'attribute';
  gender: string;
  age: number;
  signup_date: string;
  is_phone_verified: boolean;
  is_age_verified: boolean;
  status: string;
  is_income_proof: boolean;
  is_asset_proof: boolean;
  is_question_like: boolean;
  is_beginner: boolean;
  is_secret: boolean;
  is_freezed: boolean;
  is_warning: boolean;
  points: number;
  rich_like_points: number;
  is_top_image: boolean;
  is_sub_image: boolean;
  is_profile_movie: boolean;
  is_one_word: boolean;
  residence_location: string;
  income_type: string;
  asset_type: string;
};

export type DataLayerType = ViewEvent | InViewEvent | ClickEvent | IdentifyEvent | AttributeEvent;

export const pushDataLayer = (data: DataLayerType) => {
  /**
   * データレイヤーは、Google タグ マネージャーと gtag.js でタグに情報を渡すために使用するオブジェクト。
   * イベントまたは変数を、データレイヤーを介して渡したり、変数の値に基づいたトリガーを設定したりすることができる。
   */
  let { dataLayer }: any = window;

  dataLayer = dataLayer || [];
  dataLayer.push(data);
};

/**
 * identifyイベント発火
 */
export const pushDataLayerIdentify = (clientMe: Me, serverMe: Me) => {
  if (
    clientMe.id !== serverMe.id ||
    clientMe.email !== serverMe.email ||
    clientMe.phone !== serverMe.phone ||
    clientMe.property.nickname !== serverMe.property.nickname
  ) {
    pushDataLayer({
      event: 'identify',
      user_id: serverMe.id.toString(),
      email: serverMe.email,
      phone: serverMe.phone,
      name: serverMe.property.nickname
    });
  }
};

/**
 * attributeイベント発火
 */
export const pushDataLayerAttribute = (clientMe: Me, serverMe: Me) => {
  if (
    clientMe.gender !== serverMe.gender ||
    clientMe.age !== serverMe.age ||
    clientMe.created_at !== serverMe.created_at ||
    clientMe.state !== serverMe.state ||
    clientMe.is_age_verified !== serverMe.is_age_verified ||
    clientMe.patch_status !== serverMe.patch_status ||
    (clientMe.income_proof?.status === 'pass') !== (serverMe.income_proof?.status === 'pass') ||
    (clientMe.asset_proof?.status === 'pass') !== (serverMe.asset_proof?.status === 'pass') ||
    clientMe.questions.length > 0 !== serverMe.questions.length > 0 ||
    clientMe.beginner !== serverMe.beginner ||
    clientMe.is_secret !== serverMe.is_secret ||
    clientMe.is_freezed !== serverMe.is_freezed ||
    clientMe.is_warning !== serverMe.is_warning ||
    clientMe.points?.basic.current_amount !== serverMe.points?.basic.current_amount ||
    clientMe.points?.rich_like.in_time_amount !== serverMe.points?.rich_like.in_time_amount ||
    !!clientMe.top_image.id !== !!serverMe.top_image.id ||
    clientMe.images.some((i) => i.order !== 0) !== serverMe.images.some((i) => i.order !== 0) ||
    !!clientMe.profile_movie.id !== !!serverMe.profile_movie.id ||
    !!clientMe.property.one_word !== !!serverMe.property.one_word ||
    clientMe.property.residence_location !== serverMe.property.residence_location ||
    clientMe.property.income_type !== serverMe.property.income_type ||
    clientMe.property.asset_type !== serverMe.property.asset_type
  ) {
    pushDataLayer({
      event: 'attribute',
      gender: serverMe.gender === GENDER.MALE ? 'Male' : 'Female',
      age: serverMe.age,
      signup_date: serverMe.created_at,
      is_phone_verified: serverMe.state === 'completed',
      is_age_verified: serverMe.is_age_verified,
      status: serverMe.patch_status,
      is_income_proof: serverMe.income_proof?.status === 'pass',
      is_asset_proof: serverMe.asset_proof?.status === 'pass',
      is_question_like: serverMe.questions.length > 0,
      is_beginner: serverMe.beginner,
      is_secret: serverMe.is_secret,
      is_freezed: serverMe.is_freezed,
      is_warning: serverMe.is_warning,
      points: serverMe.points?.basic.current_amount,
      rich_like_points: serverMe.points?.rich_like.in_time_amount,
      is_top_image: !!serverMe.top_image.id,
      is_sub_image: serverMe.images.some((i) => i.order !== 0),
      is_profile_movie: !!serverMe.profile_movie.id,
      is_one_word: !!serverMe.property.one_word,
      residence_location: serverMe.property.residence_location,
      income_type: serverMe.property.income_type,
      asset_type: serverMe.property.asset_type
    });
  }
};
