import { memo, useCallback, useState } from 'react';
import { usePutBirthday } from '@/apis';
import { AsyncButton } from '@/components/features/AsyncButton';
import { DateDrumroll } from '@/components/styles/projects/DateDrumroll';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { zeroPadding } from '@/functions/helpers';
import { useBasicModal } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/birthdayModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  birthDay: string;
};

export const BirthdayModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, birthDay } = props;

  const birthdayData = birthDay.split('-');
  const [year, setYear] = useState(Number(birthdayData[0]));
  const [month, setMonth] = useState(Number(birthdayData[1]));
  const [date, setDate] = useState(Number(birthdayData[2]));

  const { commonModal } = useBasicModal();

  const { putBirthday } = usePutBirthday();

  const getAge = useCallback(
    (year: number, month: number, date: number) => {
      const today = new Date();
      const birthday = new Date(year, month - 1, date);
      const currentYearBirthday = new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate());
      const age = today.getFullYear() - birthday.getFullYear();

      return today < currentYearBirthday ? age - 1 : age;
    },
    [year, month, date]
  );

  const handleClickSave = useCallback(async () => {
    if (getAge(year, month, date) < 18) {
      commonModal({
        title: '18歳未満の方は登録できません。',
        text: '恐れ入りますが、18歳未満の方の登録は遠慮いただいています。ご了承ください。',
        onCloseLabel: '了解'
      });
    } else {
      const value = `${year}-${zeroPadding(month, 2)}-${zeroPadding(date, 2)}`;
      await putBirthday({ birthday: value });
      onClose();
    }
  }, [year, month, date]);

  return (
    <HalfModal
      id='birthdayModal'
      isOpen={isOpen}
      onClose={onClose}
      title='生年月日'
      isInner
      footer={
        <AsyncButton className={components.button} onClick={handleClickSave}>
          保存
        </AsyncButton>
      }
    >
      <div className={styles.heading}>
        <span className={styles.age}>満{getAge(year, month, date)}歳</span>
        <span className={styles.date}>
          {year}年{month}月{date}日
        </span>
      </div>

      <DateDrumroll
        year={year}
        month={month}
        date={date}
        setYear={(v) => setYear(v)}
        setMonth={(v) => setMonth(v)}
        setDate={(v) => setDate(v)}
      />
    </HalfModal>
  );
});
