import { memo, useCallback, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { MultiCheckBox } from '@/components/styles/uis/MultiCheckBox';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { optionElements } from '@/functions/constants/optionElements';
import components from '@/styles/components/index.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  target: string;
  value: string[];
  onSave: (value: string[]) => void;
  isProfile?: boolean;
};

export const SelectPropertyModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, target, value, onSave, isProfile } = props;

  const [element, setElement] = useState<{ title: string; option: {} }>({ title: '', option: {} });
  const [temporarily, setTemporarily] = useState<string[]>([]);

  const { isMale } = useAtomValue(meFlagAtom);
  const masterTypes = useAtomValue(masterTypesAtom);

  useEffect(() => {
    if (!isOpen || !target) return;

    const getTarget = (target: string) => {
      if (target === 'body_type') {
        return isMale ? 'female_body_type' : 'male_body_type';
      }

      return target;
    };

    const element = optionElements.find((v) => v.key === getTarget(target));

    if (!element) return;

    const option = element.option ? element.option : masterTypes[element.type!];

    const getKey = (option: { [key: string]: string }, value: string) => {
      // eslint-disable-next-line
      for (const key in option) {
        if (option[key] === value) {
          return key;
        }
      }

      return null;
    };

    const getKeys = (option: { [key: string]: string }, value: string[]) => {
      if (!value.length) return [];

      return value.map((v) => getKey(option, v));
    };

    if (isProfile) {
      setTemporarily(getKeys(option, value) as string[]);
    } else {
      setTemporarily(value);
    }
    setElement({ title: element.title, option });
  }, [isOpen, target]);

  const onCloseModal = useCallback(() => {
    onClose();
  }, []);

  const onChangeValue = useCallback(
    (value: string) => {
      if (temporarily.some((v) => v === value)) {
        setTemporarily(temporarily.filter((v) => v !== value));
      } else {
        setTemporarily([...temporarily, value]);
      }
    },
    [temporarily]
  );

  const handleClickSave = useCallback(() => {
    onSave(temporarily);
  }, [temporarily]);

  return (
    <HalfModal
      id='selectPropertyModal'
      isOpen={isOpen}
      onClose={onCloseModal}
      title={element.title}
      footer={
        <div className={components['row-button-wrapper']}>
          <button type='button' className={components.button} data-color='white' onClick={() => setTemporarily([])}>
            選択を解除
          </button>
          <button type='button' className={components.button} data-color='black' onClick={handleClickSave}>
            決定
          </button>
        </div>
      }
    >
      <MultiCheckBox selected={temporarily} options={element.option} onChange={(e) => onChangeValue(e.target.value)} />
    </HalfModal>
  );
});
