import { useCallback } from 'react';
import { client } from '@/apis/client';
import { ChatRoom } from '@/functions/types/chatRoom';
import { LikeFrom } from '@/functions/types/likeFrom';

type Params = {
  id: number;
  body: {
    like_from: LikeFrom;
  };
};

type Response = ChatRoom;

const api = (params: Params): Promise<Response> => {
  return client.post(`v1/users/${params.id}/talks`, params.body);
};

type Props = Params;

/**
 * メッセージ送信 or いいね!
 */

export const usePostIdTalks = () => {
  const postIdTalks = useCallback(async (props: Props) => {
    const res = await api(props);

    return res.id;
  }, []);

  return { postIdTalks };
};
