import { memo, useCallback, useRef, useState } from 'react';
import { Drumroll } from '@/components/styles/uis/Drumroll';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/projects/timeDrumroll.module.scss';

const rowHeight = 56;

const min = 130;
const max = 220;
const options = [...Array(max - min + 1)].map((_, index) => min + index);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  height: number;
  onSave: (data: { height: number }) => void;
};

export const HeightSelectModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, height, onSave } = props;

  const drumRoll = useRef<HTMLUListElement>(null);

  const [value, setValue] = useState(height ?? min);

  const handleClickSave = useCallback(() => {
    onSave({ height: value });
  }, [value]);

  const onScrollDrumroll = useCallback(() => {
    const setIndex = Math.round(drumRoll.current!.scrollTop / rowHeight);
    setValue(options[setIndex]);
  }, []);

  return (
    <HalfModal
      id='heightSelectModal'
      isOpen={isOpen}
      onClose={onClose}
      title='身長'
      footer={
        <button type='button' className={components.button} data-color='black' onClick={handleClickSave}>
          決定
        </button>
      }
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <Drumroll drumrollRef={drumRoll} value={value} options={options} onScroll={onScrollDrumroll} unit='cm' />
          </div>
        </div>
      </div>
    </HalfModal>
  );
});
