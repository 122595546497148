import { memo } from 'react';
import ChanceTimeComplete from '@/assets/chance_time_complete.png';
import ChanceTimeStart from '@/assets/chance_time_start.png';
import { BasicModal } from '@/components/styles/uis/BasicModal';
import components from '@/styles/components/index.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ChanceTimeStartModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose } = props;

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} viewName='popover_chancetime_start'>
      <img src={ChanceTimeStart} alt='チャンスタイム開始' />
      <div className={components['large-basic-wrapper']}>
        <div className={components['gap-wrapper-narrow']}>
          <button type='button' className={components.button} data-color='black' onClick={onClose}>
            閉じる
          </button>
        </div>
      </div>
    </BasicModal>
  );
});

export const ChanceTimeCompleteModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose } = props;

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} viewName='popover_chancetime_complete'>
      <img src={ChanceTimeComplete} alt='チャンスタイム完了' />
      <div className={components['large-basic-wrapper']}>
        <div className={components['gap-wrapper-narrow']}>
          <button type='button' className={components.button} data-color='black' onClick={onClose}>
            閉じる
          </button>
        </div>
      </div>
    </BasicModal>
  );
});
