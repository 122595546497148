import { memo, useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import InfiniteScroller from 'react-infinite-scroller';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetNotices, usePostNoticesReadAll, usePutNoticesRead } from '@/apis';
import { badgeKeys, noticeKeys } from '@/apis/queryKeys';
import { BasicList } from '@/components/styles/uis/BasicList';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { slideVariants } from '@/functions/constants/framerMotion';
import { formatDate } from '@/functions/helpers';
import { useBasicModal, useDisclosure, useSnackbar } from '@/functions/hooks';
import { Notice } from '@/functions/types/notices';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/news.module.scss';

type Props = {
  onClose: () => void;
};

export const News: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const [newsDetail, setNewsDetail] = useState<Notice | null>(null);

  const queryClient = useQueryClient();

  const newsDetailModal = useDisclosure();
  const { onCloseModal, commonModal } = useBasicModal();
  const { openSnackbar } = useSnackbar();

  const { fetchNotices } = useGetNotices();
  const { fetchNoticesReadAll } = usePostNoticesReadAll();
  const { readNotices } = usePutNoticesRead();

  const { isLoading, isFetching, hasNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: noticeKeys.lists(),
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchNotices({ page: pageParam });
      return data;
    },
    getNextPageParam: (lastPage, allPage) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return allPage.length + 1;
    }
  });

  const refetch = useCallback(() => {
    queryClient.invalidateQueries(noticeKeys.lists());
    queryClient.invalidateQueries(badgeKeys.all);
  }, []);

  const onClickAllRead = useCallback(async () => {
    await fetchNoticesReadAll();
    refetch();
    onCloseModal();
    openSnackbar({
      type: 'toast',
      text: 'すべて既読にしました。'
    });
  }, []);

  const handleClickNoticeAllRead = useCallback(() => {
    commonModal({
      title: 'お知らせをすべて既読にしますか？',
      onClickLabel: 'すべて既読',
      onClick: onClickAllRead
    });
  }, []);

  const handleClickMenu = useCallback(async (notice: Notice) => {
    await readNotices({ id: notice.user_notice_id });
    refetch();
    setNewsDetail(notice);
    newsDetailModal.open();
  }, []);

  const handleCloseDetail = useCallback(() => {
    newsDetailModal.close();
  }, []);

  const convertContent = useCallback((content: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.split(urlRegex).map((part) => {
      if (part.match(urlRegex)) {
        return (
          <a href={part} target='_blank' rel='noopener noreferrer' className={styles['news-detail-link']}>
            {part}
          </a>
        );
      }
      return part;
    });
  }, []);

  const noticesItems = useMemo(() => {
    if (!data?.pages.length) return [];

    return data.pages
      .flatMap((v) => v)
      .map((notice) => {
        return {
          key: notice.user_notice_id,
          label: notice.title,
          subLabel: formatDate(notice.created_at, { hideWeekday: true }),
          noread: notice.status === 'unread',
          onClick: () => {
            handleClickMenu(notice);
          }
        };
      });
  }, [data]);

  return (
    <>
      <TrackPageView viewName='notice_list' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>お知らせ</p>
              <button type='button' onClick={handleClickNoticeAllRead} className={styles['all-read-button']}>
                すべて既読
              </button>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            {isLoading && (
              <div className={components['center-container']}>
                <LoadingSpinner />
              </div>
            )}

            {!isLoading && noticesItems.length ? (
              <>
                <div
                  className={clsx(components['parent-wrapper'], styles.inner)}
                  data-children-open={newsDetailModal.isOpen}
                >
                  <InfiniteScroller
                    pageStart={1}
                    hasMore={hasNextPage}
                    // @ts-ignore
                    loadMore={!isFetching ? fetchNextPage : () => null}
                    loader={
                      isFetching ? (
                        <div key={0} className={clsx(components['mt-gutter'], components['align-center'])}>
                          <LoadingSpinner size={24} />
                        </div>
                      ) : undefined
                    }
                    useWindow={false}
                  >
                    <BasicList items={noticesItems} isSeparateBorder />
                  </InfiniteScroller>
                </div>

                <AnimatePresence mode='wait'>
                  {newsDetail && newsDetailModal.isOpen && (
                    <>
                      <TrackPageView viewName='notice_detail' />

                      <motion.div
                        initial='right'
                        animate='enter'
                        exit='right'
                        variants={slideVariants}
                        className={styles['news-detail-modal-wrapper']}
                      >
                        <div className={styles['news-detail-modal-header']}>
                          <button
                            type='button'
                            onClick={handleCloseDetail}
                            className={components['back-link-button']}
                            aria-label='close'
                          />
                        </div>
                        <div className={styles['news-detail-modal-contents']}>
                          <div className={styles['news-detail']}>
                            <div className={styles['news-detail-header']}>
                              <p className={styles['news-detail-title']}>{newsDetail.title}</p>
                              <span className={styles['news-detail-date']}>
                                {formatDate(newsDetail.created_at, { hideWeekday: true })}
                              </span>
                            </div>
                            <p className={styles['news-detail-content']}>{convertContent(newsDetail.content)}</p>
                          </div>
                        </div>
                      </motion.div>
                    </>
                  )}
                </AnimatePresence>
              </>
            ) : (
              <>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 147 147'
                  fill='none'
                  className={styles['empty-image']}
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M67.4167 18.7501C67.4167 15.3903 70.1403 12.6667 73.5 12.6667C76.8597 12.6667 79.5833 15.3903 79.5833 18.7501V19.1279C117.3 23.9879 117.3 74.3874 117.3 91.7501L128.25 116.083H18.75L29.7 93.6219C29.7 74.4784 29.7 23.9923 67.4167 19.1281V18.7501ZM56.2382 36.733C60.0358 33.3746 65.3302 30.9167 73.5 30.9167C81.6708 30.9167 86.9785 33.376 90.7861 36.7253C94.8376 40.289 97.9997 45.5964 100.316 52.3523C105.042 66.1351 105.133 82.4224 105.133 91.7501V94.3615L109.433 103.917H38.2167L41.8667 96.4296V93.6219C41.8667 83.3199 41.9609 66.5642 46.7014 52.5351C49.0243 45.6608 52.1934 40.3102 56.2382 36.733Z'
                    fill='#BFBFBF'
                  />
                  <path
                    d='M73.5 134.333C80.2195 134.333 85.6667 128.886 85.6667 122.167H61.3333C61.3333 128.886 66.7805 134.333 73.5 134.333Z'
                    fill='#BFBFBF'
                  />
                </svg>
                <p className={styles['empty-text']}>新着のお知らせはありません。</p>
              </>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
});
