import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { usePostResendEmailToken, usePutEmailAuth, usePutEmailWithToken } from '@/apis';
import { AsyncButton } from '@/components/features/AsyncButton';
import { BasicPanel } from '@/components/styles/projects/BasicPanel';
import { FullModal } from '@/components/styles/uis/FullModal';
import { OtpInput } from '@/components/styles/uis/OtpInput';
import { TextInput } from '@/components/styles/uis/TextInput';
import { meAtom } from '@/contexts/atoms/me';
import { otpLength } from '@/functions/constants/common';
import { slideVariants } from '@/functions/constants/framerMotion';
import { maskValue } from '@/functions/helpers';
import { emailSchema } from '@/functions/schemas/common';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/phoneVerifyModal.module.scss';

const schema = z.object({
  email: emailSchema
});

type Schema = z.infer<typeof schema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const EmailVerifyModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose } = props;

  const [step, setStep] = useState(1);
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState(false);

  const { email } = useAtomValue(meAtom);

  const isDisabledResend = useRef(false);

  const { putEmailWithToken } = usePutEmailWithToken();
  const { resendEmailToken } = usePostResendEmailToken();
  const { emailAuth } = usePutEmailAuth();

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = useForm<Schema>({
    mode: 'onChange',
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (isOpen) {
      // コンポーネントがレンダリングされたタイミングで 値が入らないことがあるためフォームをリセット
      reset({ email });
    }
  }, [isOpen]);

  const onSubmit: SubmitHandler<Schema> = (formData) => {
    putEmailWithToken(formData);
    setStep(2);
  };

  const handleClickVerify = useCallback(async () => {
    try {
      await emailAuth({ token: code });
      onClose();
      setCode('');
      setStep(1);
    } catch {
      setCodeError(true);
    }
  }, [code]);

  const handleClickReEnter = useCallback(() => {
    setCode('');
    setStep(1);
  }, []);

  const handleClickResend = useCallback(async () => {
    if (isDisabledResend.current) return;

    isDisabledResend.current = true;
    await resendEmailToken();

    setTimeout(() => {
      isDisabledResend.current = false;
    }, 3000);
  }, []);

  return (
    <FullModal id='emailVerifyModal' isOpen={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <button
            type='button'
            className={styles['close-button']}
            onClick={step === 1 ? onClose : handleClickReEnter}
            aria-label='close'
            data-prev={step !== 1}
          />
          <p>メールアドレスを変更</p>
        </div>
        <AnimatePresence mode='wait' initial={false}>
          {step === 1 && (
            <motion.div key='step1' initial='left' animate='enter' exit='left' variants={slideVariants}>
              <div className={styles['heading-wrapper']}>
                <h2 className={components['heading-1']}>メールアドレスを入力してください。</h2>
              </div>
              <BasicPanel height={158}>
                <div className={components['gap-wrapper']}>
                  <TextInput
                    id='email'
                    value={watch('email')}
                    register={register('email')}
                    placeholder='メールアドレス'
                    annotation={errors.email?.message}
                    isInvalid={'email' in { ...errors }}
                  />
                  <AsyncButton
                    className={components.button}
                    onClick={handleSubmit(onSubmit)}
                    data-disabled={!isValid || !isDirty}
                    disabled={!isValid || !isDirty}
                  >
                    次へ
                  </AsyncButton>
                </div>
              </BasicPanel>
            </motion.div>
          )}

          {step === 2 && (
            <motion.div key='step2' initial='right' animate='enter' exit='right' variants={slideVariants}>
              <div className={styles['heading-wrapper']}>
                <h2 className={components['heading-1']}>認証コードを入力してください。</h2>
                <p className={components['basic-text']}>
                  {maskValue(watch('email'))}宛に届いた認証コードを入力してください。
                </p>
              </div>
              <BasicPanel height={200}>
                <div className={styles['code-gap-wrapper']}>
                  <OtpInput value={code} inputLength={otpLength} onChange={setCode} isInvalid={codeError} />
                  <AsyncButton
                    className={components.button}
                    onClick={handleClickVerify}
                    data-disabled={code.length !== otpLength}
                    disabled={code.length !== otpLength}
                  >
                    認証
                  </AsyncButton>
                  <div className={components['space-around-wrapper']}>
                    <button type='button' className={components.link} onClick={handleClickResend}>
                      コードを再送
                    </button>
                    <button type='button' className={components.link} onClick={handleClickReEnter}>
                      メールアドレスを再入力
                    </button>
                  </div>
                </div>
              </BasicPanel>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </FullModal>
  );
});
