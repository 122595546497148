import { memo } from 'react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import FemaleSample1 from '@/assets/img_sample_female_01.png';
import FemaleSample2 from '@/assets/img_sample_female_02.png';
import FemaleSample3 from '@/assets/img_sample_female_03.png';
import FemaleSample4 from '@/assets/img_sample_female_04.png';
import FemaleSample5 from '@/assets/img_sample_female_05.png';
import FemaleSample6 from '@/assets/img_sample_female_06.png';
import FemaleSample7 from '@/assets/img_sample_female_07.png';
import FemaleSample8 from '@/assets/img_sample_female_08.png';
import FemaleSample9 from '@/assets/img_sample_female_09.png';
import MaleSample1 from '@/assets/img_sample_male_01.png';
import MaleSample2 from '@/assets/img_sample_male_02.png';
import MaleSample3 from '@/assets/img_sample_male_03.png';
import MaleSample4 from '@/assets/img_sample_male_04.png';
import MaleSample5 from '@/assets/img_sample_male_05.png';
import MaleSample6 from '@/assets/img_sample_male_06.png';
import MaleSample7 from '@/assets/img_sample_male_07.png';
import MaleSample8 from '@/assets/img_sample_male_08.png';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/sampleImgModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SampleImgModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose } = props;

  const { isMale } = useAtomValue(meFlagAtom);

  return (
    <HalfModal id='sampleImg' isOpen={isOpen} onClose={onClose} title='魅力的な写真の選び方' isInner>
      <div className={components['gap-wrapper-wide']}>
        {isMale ? (
          <>
            <div className={components['gap-wrapper']}>
              <div className={clsx(styles['sample-img-modal-heading'], styles['sample-img-modal-heading-good'])}>
                <span>女性にモテる画像</span>
              </div>
              <ul className={styles['sample-img-modal-list']}>
                <li>
                  <img src={MaleSample1} alt='男性サンプル1' />
                  <span>自身のお顔がわかる</span>
                </li>
                <li>
                  <img src={MaleSample2} alt='男性サンプル2' />
                  <span>趣味を楽しむ画像</span>
                </li>
                <li>
                  <img src={MaleSample3} alt='男性サンプル3' />
                  <span>趣味の画像 (例: 時計)</span>
                </li>
                <li>
                  <img src={MaleSample4} alt='男性サンプル4' />
                  <span>趣味の画像 (例: 車)</span>
                </li>
                <li>
                  <img src={MaleSample5} alt='男性サンプル5' />
                  <span>趣味の画像 (例: 料理)</span>
                </li>
              </ul>
            </div>
            <div className={components['gap-wrapper']}>
              <div className={clsx(styles['sample-img-modal-heading'], styles['sample-img-modal-heading-bad'])}>
                <span>登録できない画像</span>
              </div>
              <ul className={styles['sample-img-modal-list']}>
                <li>
                  <img src={MaleSample6} alt='男性サンプル6' />
                  <span>複数人</span>
                </li>
                <li>
                  <img src={MaleSample7} alt='男性サンプル7' />
                  <span>他人の画像</span>
                </li>
                <li>
                  <img src={MaleSample8} alt='男性サンプル8' />
                  <span>過度な加工</span>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className={components['gap-wrapper']}>
              <div className={clsx(styles['sample-img-modal-heading'], styles['sample-img-modal-heading-good'])}>
                <span>登録できる画像</span>
              </div>
              <ul className={styles['sample-img-modal-list']}>
                <li>
                  <img src={FemaleSample1} alt='女性サンプル1' />
                  <span>他撮り</span>
                </li>
                <li>
                  <img src={FemaleSample2} alt='女性サンプル2' />
                  <span>自撮り</span>
                </li>
                <li>
                  <img src={FemaleSample3} alt='女性サンプル3' />
                  <span>全身画像</span>
                </li>
              </ul>
            </div>
            <div className={components['gap-wrapper']}>
              <div className={clsx(styles['sample-img-modal-heading'], styles['sample-img-modal-heading-bad'])}>
                <span>登録できない画像</span>
              </div>
              <ul className={styles['sample-img-modal-list']}>
                <li>
                  <img src={FemaleSample4} alt='女性サンプル4' />
                  <span>複数人</span>
                </li>
                <li>
                  <img src={FemaleSample5} alt='女性サンプル5' />
                  <span>
                    お顔全体が
                    <br />
                    わからない
                  </span>
                </li>
                <li>
                  <img src={FemaleSample6} alt='女性サンプル6' />
                  <span>過度な加工</span>
                </li>
                <li>
                  <img src={FemaleSample7} alt='女性サンプル7' />
                  <span>他人の画像</span>
                </li>
                <li>
                  <img src={FemaleSample8} alt='女性サンプル8' />
                  <span>
                    お顔が
                    <br />
                    全く見えない
                  </span>
                </li>
                <li>
                  <img src={FemaleSample9} alt='女性サンプル9' />
                  <span>人物以外</span>
                </li>
              </ul>
            </div>
          </>
        )}
        <button type='button' className={components.button} data-color='black' onClick={onClose}>
          閉じる
        </button>
      </div>
    </HalfModal>
  );
});
