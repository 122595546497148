import { ReactNode, Suspense, memo, useEffect, useState } from 'react';
// import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';
import { pushDataLayerAttribute, pushDataLayerIdentify } from '@/analytics/pushDataLayer';
import { useGetBadges, useGetMe, useGetTypes } from '@/apis';
import { badgeKeys, meKeys } from '@/apis/queryKeys';
import { badgesAtom } from '@/contexts/atoms/badges';
import { meAtom } from '@/contexts/atoms/me';
import { getMeFlag, meFlagAtom } from '@/contexts/atoms/meFlag';
import { LOCAL_STORAGE } from '@/functions/constants/enums';

type Props = {
  children: ReactNode;
};

export const AppSetup: React.FC<Props> = memo((props) => {
  const { children } = props;

  const [isLoading, setIsLoading] = useState(true);

  const { fetchTypes } = useGetTypes();
  const { fetchtMe } = useGetMe();
  const { fetchBadge } = useGetBadges();

  const [me, setMe] = useAtom(meAtom);
  const setMeFlag = useSetAtom(meFlagAtom);
  const setBadge = useSetAtom(badgesAtom);

  const { isFetched } = useQuery({
    queryKey: meKeys.all,
    queryFn: async () => {
      if (!localStorage.getItem(LOCAL_STORAGE.PARIV_TOKEN.KEY)) return null;

      const res = await fetchtMe();
      setMe(res);
      setMeFlag(getMeFlag(res));

      // KARTE identifyイベント発火
      pushDataLayerIdentify(me, res);
      // KARTE attributeイベント発火
      pushDataLayerAttribute(me, res);

      return res;
    }
  });

  useQuery({
    queryKey: badgeKeys.all,
    queryFn: async () => {
      if (!localStorage.getItem(LOCAL_STORAGE.PARIV_TOKEN.KEY)) return null;

      const res = await fetchBadge();
      setBadge(res);

      return res;
    }
  });

  useEffect(() => {
    (async () => {
      await fetchTypes();
      setIsLoading(false);
    })();
  }, []);

  return (
    // TODO: 必要なら全体ローディング画面を入れる
    // <Suspense fallback={<div>loading...</div>}>
    <Suspense>
      {/* TODO: 必要ならエラー画面の作成 */}
      {/* <ErrorBoundary FallbackComponent={<p>error</p>}> */}

      {!isLoading && isFetched && children}
    </Suspense>
  );
});
