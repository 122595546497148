import { memo, useCallback, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { useInView } from 'react-intersection-observer';
import { ChipLabel } from '@/components/styles/projects/ChipLabel';
import { OnlineStatus } from '@/components/styles/projects/OnlineStatus';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { checkNew, getUserImage } from '@/functions/helpers';
import { useImpression } from '@/functions/hooks/useImpression';
import { UserBasicInfo } from '@/functions/types/userBasicInfo';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/projects/profileCard.module.scss';

type Props = {
  user: UserBasicInfo;
  onClickUser?: (id: number) => void;

  // NOTE: チュートリアル画面で使用
  isLike?: boolean;
  isLikeButton?: boolean;
  onClickLike?: (id: number) => void;
};

export const ProfileCard: React.FC<Props> = memo((props) => {
  const { user, onClickUser, isLike, isLikeButton, onClickLike } = props;

  const { isMale, isBeginner } = useAtomValue(meFlagAtom);

  const { ref, inView } = useInView({
    triggerOnce: true
  });

  const { setUserId } = useImpression();

  const getImageLength = useCallback((length: number) => {
    if (length > 1) {
      return <span>+{length - 1}</span>;
    }

    return null;
  }, []);

  useEffect(() => {
    if (inView) {
      setUserId(user.id);
    }
  }, [inView]);

  return (
    <li
      className={styles.card}
      data-royal={!isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal')}
      ref={ref}
    >
      {onClickUser ? (
        <button type='button' onClick={() => onClickUser(user.id)}>
          <div className={styles['main-img']}>
            <img src={getUserImage(user, isBeginner)} alt='' width={164} height={228} />
            {user.images.length > 1 && (
              <div className={styles['sub-img']}>
                <img src={user.images[1].image_url} alt='' width={164} height={228} />
                {getImageLength(user.images.length)}
              </div>
            )}
            <div className={styles['main-img-icon']}>
              {!isBeginner && user.asset_proof && user.asset_proof.status === 'pass' && <span data-icon='asset' />}
              {!isBeginner && user.income_proof && user.income_proof.status === 'pass' && <span data-icon='income' />}
            </div>
            {!isBeginner && user.profile_movie.passed_movie_url && <span className={styles['play-icon']} />}
          </div>
        </button>
      ) : (
        <div className={styles['main-img']}>
          <img src={getUserImage(user, isBeginner)} alt='' width={164} height={228} />
          {user.images.length > 1 && (
            <div className={styles['sub-img']}>
              <img src={user.images[1].image_url} alt='' width={164} height={228} />
              {getImageLength(user.images.length)}
            </div>
          )}
          {!isBeginner && user.profile_movie.passed_movie_url && <span className={styles['play-icon']} />}
        </div>
      )}
      <div className={styles.info}>
        <OnlineStatus time={user.last_action_time} isSmall />
        <span>
          {user.age}歳 {user.property.residence_location}
        </span>
      </div>
      {isMale && <p className={components['text-break']}>{user.property.one_word}</p>}
      {!isMale && !isBeginner && <p>{user.income}</p>}

      {isLikeButton ? (
        <button
          type='button'
          className={styles['like-button']}
          onClick={() => onClickLike!(user.id)}
          data-check={isLike}
          aria-label='like'
        />
      ) : (
        checkNew(user.created_at) && (
          <span className={styles['chip-label']}>
            <ChipLabel label='NEW' color='red' />
          </span>
        )
      )}
    </li>
  );
});
