import { memo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import InfiniteScroller from 'react-infinite-scroller';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetPointHistory } from '@/apis';
import { pointKeys } from '@/apis/queryKeys';
import { ReactComponent as EmptyPointHistory } from '@/assets/icons/empty_point.svg';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { slideVariants } from '@/functions/constants/framerMotion';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/pointHistory.module.scss';

type Props = {
  onClose: () => void;
};

export const PointHistory: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const { basicPointLength } = useAtomValue(meFlagAtom);

  const { fetchPointHistory } = useGetPointHistory();

  const { isLoading, isFetching, hasNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: pointKeys.histories(),
    queryFn: async ({ pageParam = 1 }) => {
      const datas = await fetchPointHistory({ page: pageParam });
      return datas;
    },
    getNextPageParam: (lastPage, allPage) => {
      if (lastPage.length === 0) return undefined;
      return allPage.length + 1;
    }
  });

  return (
    <>
      <TrackPageView viewName='point_history' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>ポイント履歴</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            {isLoading && (
              <div className={styles['empty-wrapper']}>
                <LoadingSpinner />
              </div>
            )}
            {!isLoading && data?.pages.flatMap((v) => v).length ? (
              <div className={styles.inner}>
                <div className={styles['point-wrapper']}>
                  <span className={styles['point-text']}>残ポイント</span>
                  <span className={styles['point-length']}>
                    <span className={components['text-bold']}>{basicPointLength ?? 0}</span>
                    PT
                  </span>
                </div>

                <p className={styles.heading}>ポイント履歴</p>
                <InfiniteScroller
                  pageStart={1}
                  hasMore={hasNextPage}
                  // @ts-ignore
                  loadMore={!isFetching ? fetchNextPage : () => null}
                  loader={
                    isFetching ? (
                      <div key={0} className={clsx(components['mt-gutter'], components['align-center'])}>
                        <LoadingSpinner size={24} />
                      </div>
                    ) : undefined
                  }
                  useWindow={false}
                >
                  <ul className={styles['record-list']}>
                    {data.pages
                      .flatMap((v) => v)
                      .map((record, index) => (
                        // eslint-disable-next-line
                        <li key={`${record.created_at}-${index}`} className={styles['record-item']}>
                          <div>
                            <p className={styles['record-name']}>{record.distribution.name}</p>
                            <span className={styles['record-date']}>{record.created_at}</span>
                          </div>
                          <span className={styles['record-point']}>
                            <span className={styles['record-point-length']} data-sign={record.point > 0}>
                              {record.point > 0 && '+'}
                              {record.point}
                            </span>
                            PT
                          </span>
                        </li>
                      ))}
                  </ul>
                </InfiniteScroller>
              </div>
            ) : (
              <div className={styles['empty-wrapper']}>
                <EmptyPointHistory />
                <p className={clsx(components['heading-1'], components['mt-gutter'])}>ポイント履歴はありません。</p>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
});
