import { memo, useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import { EmailVerifyModal } from '@/components/features/modal/EmailVerifyModal';
import { ResignModal } from '@/components/features/modal/ResignModal';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { resignModalAtom } from '@/contexts/atoms/resignModal';
import { LOCAL_STORAGE } from '@/functions/constants/enums';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import { ConfigRouteTarget } from '@/functions/constants/subElementRoutes';
import { removeLocalStorage } from '@/functions/helpers';
import { useBasicModal, useDisclosure, usePhoneVerify, useResignModal, useSubElement } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/config.module.scss';

const parentName = 'config';

type Section = {
  title: string;
  items: {
    target: ConfigRouteTarget;
    label: string;
  }[];
};

type Props = {
  onClose: () => void;
};

export const Config: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const me = useAtomValue(meAtom);
  const { member, isMale, isBeginner, isPhoneVerify } = useAtomValue(meFlagAtom);

  const resignModal = useAtomValue(resignModalAtom);

  const emailVerifyModal = useDisclosure();

  const { onCloseModal, commonModal, phoneVerificationModal, adminStopHackModal } = useBasicModal();
  const { parentElements, handleOpenSubElement } = useSubElement();
  const { handleOpenPhoneVerify } = usePhoneVerify();
  const { handleCloseResignModal } = useResignModal();

  const isHack = useMemo(() => {
    return !!localStorage.getItem(LOCAL_STORAGE.PARIV_MANAGED_USER_ID.KEY);
  }, []);

  const sections: Section[] = useMemo(() => {
    return [
      {
        title: 'アカウント',
        items: [
          {
            target: 'phone',
            label: '電話番号を変更'
          },
          {
            target: 'email',
            label: 'メールアドレスを変更'
          },
          {
            target: 'notification',
            label: '通知設定'
          },
          {
            target: 'secretMode',
            label: 'シークレットモード設定'
          },
          {
            target: 'pointHistory',
            label: 'ポイント履歴'
          },
          {
            target: 'hideUserList',
            label: '非表示リスト'
          },
          {
            target: 'blockUserList',
            label: 'ブロックリスト'
          },
          {
            target: 'quitUserList',
            label: '退会者リスト'
          }
        ]
      },
      ...(member === 'admin' || isHack
        ? [
            {
              title: 'Admin',
              items: [
                ...(member === 'admin'
                  ? [
                      {
                        target: 'admin' as ConfigRouteTarget,
                        label: 'のっとり機能'
                      }
                    ]
                  : []),
                ...(isHack
                  ? [
                      {
                        target: 'stopHack' as ConfigRouteTarget,
                        label: 'のっとりを停止する'
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),
      {
        title: 'サポート',
        items: [
          {
            target: 'faq',
            label: 'よくある質問'
          },
          {
            target: 'media',
            label: 'paddy公式メディア'
          },
          {
            target: 'howto',
            label: 'paddyの使い方'
          },
          {
            target: 'safetyLp',
            label: '安心・安全の取り組み'
          },
          {
            target: 'contact',
            label: 'お問い合わせ'
          },
          {
            target: 'unsubscribePlan',
            label: '購読の解約'
          }
        ]
      },
      {
        title: 'その他',
        items: [
          {
            target: 'terms',
            label: '利用規約'
          },
          {
            target: 'privacyPolicy',
            label: 'プライバシーポリシー'
          },
          {
            target: 'asct',
            label: '特定商取引法に基づく表記'
          },
          {
            target: 'psa',
            label: '資金決済法に基づく表記'
          },
          {
            target: 'logout',
            label: 'ログアウト'
          }
        ]
      }
    ];
  }, [member, isHack]);

  const onLogout = useCallback(async () => {
    onCloseModal();

    setTimeout(() => {
      // NOTE: ローカルストレージの一括削除
      removeLocalStorage('logout');

      window.location.href = '/web';
    }, animateDuration);
  }, []);

  const onStopHack = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE.PARIV_MANAGED_USER_ID.KEY);
    window.location.reload();
    window.location.href = '/web/account';
  }, []);

  const handleClickButton = useCallback(
    (target: ConfigRouteTarget) => {
      if (target === 'phone') {
        if (isMale && !isPhoneVerify) {
          phoneVerificationModal();
        } else {
          handleOpenPhoneVerify();
        }
      } else if (target === 'email') {
        emailVerifyModal.open();
      } else if (target === 'faq') {
        if (isBeginner) {
          window.open('https://paddy67.super.site/', '_blank');
        } else {
          window.open('https://paddy.super.site/', '_blank');
        }
      } else if (target === 'media') {
        window.open('https://paddy-media.com/', '_blank');
      } else if (target === 'terms') {
        window.open('https://www.paddy67.today/terms', '_blank');
      } else if (target === 'privacyPolicy') {
        window.open('https://www.paddy67.today/privacy_policy', '_blank');
      } else if (target === 'asct') {
        window.open('https://www.paddy67.today/asct', '_blank');
      } else if (target === 'psa') {
        window.open('https://www.paddy67.today/psa', '_blank');
      } else if (target === 'stopHack') {
        adminStopHackModal(me.id, me.property?.nickname, onStopHack);
      } else if (target === 'logout') {
        commonModal({
          title: 'ログアウトしますか？',
          text: 'ログイン画面に戻ります。よろしいですか？',
          onClickLabel: 'ログアウト',
          onClick: onLogout,
          viewName: 'dialog_logout'
        });
      } else {
        handleOpenSubElement(target, parentName);
      }
    },
    [isBeginner, isPhoneVerify]
  );

  const checkValidItem = useCallback((target: ConfigRouteTarget) => {
    if (target === 'pointHistory') {
      if (isMale) {
        return true;
      }
      return false;
    }
    if (target === 'unsubscribePlan') {
      if (member === 'premium' || member === 'royal' || me.in_time_subscriptions.royal_subscription) {
        return true;
      }
      return false;
    }

    return true;
  }, []);

  return (
    <>
      <TrackPageView viewName='settings' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>設定</p>
            </div>
          </div>

          <div className={components['parent-wrapper']} data-children-open={parentElements.second === parentName}>
            <div className={account['submodal-contents']}>
              <div className={styles.inner}>
                {sections.map(({ title, items }) => (
                  <div key={title} className={styles.section}>
                    <div className={styles['section-inner']}>
                      <p className={styles['section-title']}>{title}</p>
                      <ul className={styles['section-list']}>
                        {items.map(
                          ({ target, label }) =>
                            checkValidItem(target) && (
                              <li key={label}>
                                <button type='button' onClick={() => handleClickButton(target)}>
                                  {label}
                                </button>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>

        <EmailVerifyModal isOpen={emailVerifyModal.isOpen} onClose={emailVerifyModal.close} />
        <ResignModal isOpen={resignModal.isOpen} onClose={handleCloseResignModal} />
      </div>
    </>
  );
});
