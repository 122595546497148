import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePostAppointments, usePostCheckDuplicateAppointments } from '@/apis';
import { appointmentKeys } from '@/apis/queryKeys';
import { AsyncButton } from '@/components/features/AsyncButton';
import { TimeDrumroll } from '@/components/styles/projects/TimeDrumroll';
import { DatePicker } from '@/components/styles/uis/DatePicker';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { Select } from '@/components/styles/uis/Select';
import { TextInput } from '@/components/styles/uis/TextInput';
import { formatDate, getDuplicateAlertText, zeroPadding } from '@/functions/helpers';
import { useBasicModal, useDisclosure } from '@/functions/hooks';
import { Schema, appointmentSchema, defaultValue } from '@/functions/schemas/chatRooms/appointment';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/appointmentModal.module.scss';

type SubElementKey = 'date' | 'start' | 'end';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  chatRoomId: number;
  refetchChat: () => void;
};

export const AppointmentModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, chatRoomId, refetchChat } = props;

  const [temporarily, setTemporarily] = useState<Date | string | null>(null);
  const [selectDate, setSelectDate] = useState<Date | null>(null);
  const [subElementKey, setSubElementKey] = useState<SubElementKey | null>(null);
  const [timeError, setTimeError] = useState({
    text: '',
    start: false,
    end: false
  });

  const { postAppointments } = usePostAppointments();
  const { postCheckDuplicate } = usePostCheckDuplicateAppointments();

  const subElementModal = useDisclosure();
  const { onCloseModal, commonModal } = useBasicModal();

  const queryClient = useQueryClient();

  const getDate = useCallback((selectDate: Date | null, time: string) => {
    const dates = new Date(selectDate ?? '');
    const year = dates.getFullYear();
    const month = zeroPadding(dates.getMonth() + 1, 2);
    const date = zeroPadding(dates.getDate(), 2);

    return `${year}-${month}-${date}T${time}+09:00`;
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: defaultValue,
    shouldFocusError: false,
    resolver: zodResolver(appointmentSchema)
  });

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    try {
      const data: Schema = {
        chat_room_id: chatRoomId,
        start_at: new Date(getDate(selectDate, formData.start_at)).toISOString(),
        end_at: new Date(getDate(selectDate, formData.end_at)).toISOString(),
        place: formData.place
      };

      await postAppointments(data);
      refetchChat();
      onClose();
      onCloseModal();
      setSelectDate(null);
      reset();

      // TODO: removeQueries --> invalidateQueries
      queryClient.removeQueries(appointmentKeys.list('future'));
    } catch (err: any) {
      commonModal({ title: err.data.error_title });
    }
  };

  const handleClickCreate = useCallback(async () => {
    const startAt = new Date(getDate(selectDate, getValues('start_at'))).toISOString();
    const endAt = new Date(getDate(selectDate, getValues('end_at'))).toISOString();

    const { board, appointment } = await postCheckDuplicate({ start_at: startAt, end_at: endAt });

    commonModal({
      title: '約束の内容を確定しますか？',
      text: '内容の確定後にお相手に通知のメッセージが送信されます。よろしいですか？',
      alertText: getDuplicateAlertText(board, appointment),
      onCloseLabel: 'キャンセル',
      onClickLabel: '確定する',
      onClick: handleSubmit(onSubmit)
    });
  }, [selectDate]);

  useEffect(() => {
    const start = getValues('start_at');
    const end = getValues('end_at');
    const now = new Date();
    const startDate = new Date(getDate(selectDate, start));
    const endDate = new Date(getDate(selectDate, end));

    if (start && now.getTime() > startDate.getTime()) {
      setTimeError({
        text: '過去の時刻は選択できません。',
        start: true,
        end: false
      });
    } else if (end && now.getTime() > endDate.getTime()) {
      setTimeError({
        text: '過去の時刻は選択できません。',
        start: false,
        end: true
      });
    } else if (start && end && startDate.getTime() > endDate.getTime()) {
      setTimeError({
        text: '終了時刻は開始時刻よりも遅い時刻にしてください。',
        start: true,
        end: true
      });
    } else {
      setTimeError({
        text: '',
        start: false,
        end: false
      });
    }
  }, [selectDate, getValues('start_at'), getValues('end_at')]);

  const isSubmitDisable = [!selectDate, !getValues('start_at'), !getValues('end_at'), !isValid].some((v) => v);

  const getSubElementTitle = useCallback((key: SubElementKey | null) => {
    switch (key) {
      case 'date':
        return '日付';
      case 'start':
        return '開始時刻';
      case 'end':
        return '終了時刻';
      default:
        return '';
    }
  }, []);

  const handleOpenSubElement = useCallback(
    (key: 'date' | 'start' | 'end') => {
      if (key === 'date' && !!selectDate) {
        setTemporarily(selectDate);
      }

      setSubElementKey(key);
      subElementModal.open();
    },
    [selectDate]
  );

  const handleCloseSubElement = useCallback(() => {
    setSubElementKey(null);
    setTemporarily(null);
    subElementModal.close();
  }, []);

  const handleClickSave = useCallback(() => {
    if (subElementKey === 'date') {
      setSelectDate(temporarily as Date);
    } else if (subElementKey === 'start') {
      setValue('start_at', temporarily as string);
    } else if (subElementKey === 'end') {
      setValue('end_at', temporarily as string);
    }

    handleCloseSubElement();
  }, [temporarily]);

  // 時間のデフォルト値を指定
  const getFormattedTime = (hour: number) => {
    const date = new Date();
    date.setHours(date.getHours() + hour);

    return `${String(date.getHours()).padStart(2, '0')}:00`;
  };

  const defaultStartAt = useMemo(() => {
    return getFormattedTime(1);
  }, [isOpen]);

  const defaultEndAt = useMemo(() => {
    return getFormattedTime(2);
  }, [isOpen]);

  return (
    <HalfModal
      id='appointmentModal'
      isOpen={isOpen}
      onClose={onClose}
      title='約束'
      isInner
      isMaxHeight
      footer={
        <AsyncButton
          className={components.button}
          data-color='black'
          onClick={handleClickCreate}
          disabled={isSubmitDisable}
          data-disabled={isSubmitDisable}
        >
          約束の内容を確定する
        </AsyncButton>
      }
    >
      <p className={styles.annotation}>デートが決まったらお相手と約束をしてみましょう。</p>
      <ul className={styles.items}>
        <li className={styles.item}>
          <p className={styles['item-heading']}>お約束の日時</p>
          <Select
            value={selectDate ? formatDate(selectDate, { hideTime: true }) : ''}
            onClick={() => handleOpenSubElement('date')}
            placeholder='日付'
          />

          <div className={styles['time-wrapper']}>
            <Select
              value={getValues('start_at')}
              onClick={() => handleOpenSubElement('start')}
              placeholder='開始時刻'
              isInvalid={timeError.start}
            />
            <span>〜</span>
            <Select
              value={getValues('end_at')}
              onClick={() => handleOpenSubElement('end')}
              placeholder='終了時刻'
              isInvalid={timeError.end}
            />
          </div>
          {timeError.text && <p className={styles['time-error-text']}>{timeError.text}</p>}
        </li>
        <li className={styles.item}>
          <p className={styles['item-heading']}>お会いする場所</p>
        </li>
        <TextInput register={register('place')} placeholder='場所の詳細' annotation='例）渋谷駅前' />
      </ul>

      <HalfModal
        id='subElement'
        isOpen={subElementModal.isOpen}
        onClose={handleCloseSubElement}
        title={getSubElementTitle(subElementKey)}
        footer={
          <button type='button' className={components.button} data-color='black' onClick={handleClickSave}>
            決定
          </button>
        }
      >
        <>
          {subElementKey === 'date' && <DatePicker date={temporarily as Date} setDate={setTemporarily} />}
          {subElementKey === 'start' && (
            <TimeDrumroll
              time={getValues('start_at') === '' ? defaultStartAt : getValues('start_at')}
              setTime={(time) => setTemporarily(time)}
            />
          )}
          {subElementKey === 'end' && (
            <TimeDrumroll
              time={getValues('end_at') === '' ? defaultEndAt : getValues('end_at')}
              setTime={(time) => setTemporarily(time)}
            />
          )}
        </>
      </HalfModal>
    </HalfModal>
  );
});
