import { memo, useState, useCallback } from 'react';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import InfiniteScroller from 'react-infinite-scroller';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetQuitUsers } from '@/apis';
import { userKeys } from '@/apis/queryKeys';
import { ReactComponent as Empty } from '@/assets/icons/empty_quit.svg';
import FemaleImg from '@/assets/img_default_female.png';
import MaleImg from '@/assets/img_default_male.png';
import { ReportModal } from '@/components/features/modal/ReportModal';
import { UserImg } from '@/components/styles/projects/UserImg';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { GENDER } from '@/functions/constants/enums';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useDisclosure } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/config/quitUserList.module.scss';

type Props = {
  onClose: () => void;
};

export const QuitUserList: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const [userId, setUserId] = useState<number | null>(null);

  const reportModal = useDisclosure();

  const queryClient = useQueryClient();

  const { fetchQuitUsers } = useGetQuitUsers();

  const { isLoading, isFetching, hasNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: userKeys.quit(),
    queryFn: async ({ pageParam = 1 }) => {
      const datas = await fetchQuitUsers({ page: pageParam });
      return datas;
    },
    getNextPageParam: (lastPage, allPage) => {
      if (lastPage.length === 0) return undefined;

      return allPage.length + 1;
    }
  });

  const handleClickReportButton = useCallback((userId: number) => {
    setUserId(userId);
    reportModal.open();
  }, []);

  const refetchReportStatus = useCallback(async () => {
    queryClient.invalidateQueries(userKeys.quit());
  }, []);

  return (
    <>
      <TrackPageView viewName='deleted_userlist' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>退会者リスト</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            {isLoading && (
              <div className={styles['center-wrapper']}>
                <LoadingSpinner />
              </div>
            )}
            {!isLoading && data?.pages.flatMap((v) => v).length ? (
              <>
                <div className={styles.inner}>
                  <InfiniteScroller
                    pageStart={1}
                    hasMore={hasNextPage}
                    // @ts-ignore
                    loadMore={!isFetching ? fetchNextPage : () => null}
                    loader={
                      isFetching ? (
                        <div key={0} className={clsx(components['mt-gutter'], components['align-center'])}>
                          <LoadingSpinner size={24} />
                        </div>
                      ) : undefined
                    }
                    useWindow={false}
                  >
                    <ul>
                      {data.pages
                        .flatMap((v) => v)
                        .map((user) => (
                          <li key={user.id} className={styles.card}>
                            <UserImg src={user.gender === GENDER.MALE ? MaleImg : FemaleImg} size={88} />
                            <div className={styles['card-content']}>
                              <div className={styles['card-header']}>
                                <p className={components['text-bold']}>{user.property.nickname}</p>
                                <span className={styles['card-property']}>
                                  {user.age}歳 {user.property.residence_location}
                                </span>
                              </div>
                              {user.property.one_word && (
                                <p className={clsx(components['basic-text'], styles['card-text'])}>
                                  {user.property.one_word}
                                </p>
                              )}
                              <div className={styles['button-wrapper']}>
                                <button
                                  type='button'
                                  className={styles.button}
                                  data-color='black'
                                  data-size='submin'
                                  data-custom='true'
                                  onClick={() => handleClickReportButton(user.id)}
                                  disabled={user.reported}
                                >
                                  {user.reported ? '通報済' : '通報'}
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </InfiniteScroller>
                </div>
                {userId && (
                  <ReportModal
                    isOpen={reportModal.isOpen}
                    onClose={reportModal.close}
                    userId={userId}
                    refetch={refetchReportStatus}
                  />
                )}
              </>
            ) : (
              <div className={styles['center-wrapper']}>
                <Empty />
                <p className={styles['empty-text']}>退会したお相手はいません。</p>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
});
