import { MasterTypeKey } from '@/contexts/atoms/masterTypes';

export type OptionElementKeyTypes =
  | 'asset_type'
  | 'before_meeting'
  | 'board_area'
  | 'board_purpose'
  | 'board_timeframe'
  | 'body_type'
  | 'business_type'
  | 'cigarette_type'
  | 'contact_general_categories'
  | 'contact_member_categories'
  | 'drinking_type'
  | 'education_type'
  | 'favorite_type'
  | 'female_body_type'
  | 'holiday_type'
  | 'hometown'
  | 'income_type'
  | 'last_login_time'
  | 'looks'
  | 'male_body_type'
  | 'meetup_location_prefs'
  | 'meetup_location'
  | 'personality'
  | 'purpose'
  | 'register_reason'
  | 'residence_location'
  | 'faceweight'
  | 'top_photo'
  | 'sub_photos'
  | 'recent_register';

type OptionElementsType = {
  key: OptionElementKeyTypes;
  title: string;
  type?: MasterTypeKey;
  option?: {};
};

export const optionElements: OptionElementsType[] = [
  {
    key: 'asset_type',
    title: '資産',
    type: 'asset_types'
  },
  {
    key: 'before_meeting',
    title: 'デートまでの希望',
    type: 'before_meeting_types'
  },
  {
    key: 'board_area',
    title: 'エリア',
    type: 'board_areas'
  },
  {
    key: 'board_purpose',
    title: '目的',
    type: 'board_purposes'
  },
  {
    key: 'board_timeframe',
    title: '時間帯',
    type: 'board_timeframes'
  },
  {
    key: 'body_type',
    title: 'スタイル',
    type: 'body_types'
  },
  {
    key: 'business_type',
    title: 'お仕事',
    type: 'business_types'
  },
  {
    key: 'cigarette_type',
    title: 'タバコ',
    type: 'cigarette_types'
  },
  {
    key: 'contact_general_categories',
    title: 'お問い合わせカテゴリ',
    type: 'contact_general_categories'
  },
  {
    key: 'contact_member_categories',
    title: 'お問い合わせカテゴリ',
    type: 'contact_member_categories'
  },
  {
    key: 'drinking_type',
    title: 'お酒',
    type: 'drinking_types'
  },
  {
    key: 'education_type',
    title: '学歴',
    type: 'education_types'
  },
  {
    key: 'favorite_type',
    title: 'お気に入り度',
    option: {
      favorite1: '★1',
      favorite2: '★2',
      favorite: '★3'
    }
  },
  {
    key: 'female_body_type',
    title: 'スタイル',
    type: 'female_body_types'
  },
  {
    key: 'holiday_type',
    title: '休日',
    type: 'holiday_types'
  },
  {
    key: 'hometown',
    title: '出身地',
    type: 'hometowns'
  },
  {
    key: 'income_type',
    title: '年収',
    type: 'income_types'
  },
  {
    key: 'last_login_time',
    title: '最終ログイン',
    type: 'last_login_times'
  },
  {
    key: 'looks',
    title: '雰囲気',
    type: 'lookses'
  },
  {
    key: 'male_body_type',
    title: 'スタイル',
    type: 'male_body_types'
  },
  {
    key: 'personality',
    title: '性格・タイプ',
    type: 'personalities'
  },
  {
    key: 'purpose',
    title: 'デートでしたいこと',
    type: 'purpose_types'
  },
  {
    key: 'register_reason',
    title: '登録した理由',
    type: 'register_reasons'
  },
  {
    key: 'residence_location',
    title: '居住地',
    type: 'residence_locations'
  },
  {
    key: 'faceweight',
    title: 'Faceweight',
    type: 'faceweight'
  },
  {
    key: 'top_photo',
    title: 'メイン画像',
    type: 'top_photo',
    option: {
      registered: '登録あり',
      unregistered: '登録なし'
    }
  },
  {
    key: 'sub_photos',
    title: 'サブ画像',
    type: 'sub_photos',
    option: {
      registered: '登録あり',
      unregistered: '登録なし'
    }
  },
  {
    key: 'recent_register',
    title: '最近入会',
    type: 'recent_register'
  }
];
