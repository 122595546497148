import { memo } from 'react';
import styles from '@/styles/uis/loadingSpinner.module.scss';

type Props = {
  size?: number;
};

export const LoadingSpinner: React.FC<Props> = memo((props) => {
  const { size = 40 } = props;

  return (
    <div className={styles.wrapper}>
      <svg width={`${size}`} height={`${size}`} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M22 12.2509C22 10.3604 21.49 8.50671 20.5264 6.8948C19.5628 5.2829 18.1831 3.97554 16.5399 3.11728C14.8967 2.25902 13.054 1.88328 11.2154 2.0316C9.37687 2.17993 7.61416 2.84654 6.12215 3.95774C4.63014 5.06894 3.46696 6.58144 2.76121 8.32803C2.05546 10.0746 1.83462 11.9872 2.12312 13.8544C2.41162 15.7217 3.19822 17.4707 4.39594 18.9083C5.59367 20.3458 7.15587 21.4158 8.90983 22L9.52787 20.0502C8.1247 19.5828 6.87494 18.7268 5.91676 17.5768C4.95857 16.4267 4.3293 15.0275 4.0985 13.5337C3.8677 12.04 4.04436 10.5099 4.60897 9.11259C5.17357 7.71533 6.10412 6.50533 7.29772 5.61637C8.49133 4.72741 9.9015 4.19412 11.3723 4.07545C12.8432 3.95679 14.3174 4.25739 15.6319 4.944C16.9465 5.63061 18.0502 6.67649 18.8211 7.96601C19.592 9.25554 20 10.7385 20 12.2509H22Z' />
      </svg>
    </div>
  );
});
