import { memo, useCallback } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { usePutAttachName } from '@/apis';
import { AsyncButton } from '@/components/features/AsyncButton';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { TextInput } from '@/components/styles/uis/TextInput';
import components from '@/styles/components/index.module.scss';

const schema = z.object({
  attached_name: z.string().max(12, '12文字以内で入力してください').nullable()
});

type Schema = z.infer<typeof schema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  chatRoomId: number;
  nickname: string;
  attachedName?: string;
  refetchChat: () => void;
};

export const NicknameModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, chatRoomId, nickname, attachedName, refetchChat } = props;

  const { putAttachName } = usePutAttachName();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: { attached_name: attachedName || nickname },
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  const onCloseModal = useCallback(() => {
    const value = getValues('attached_name');
    // 入力された値が空の場合に元の値を設定
    if (value === '') {
      setValue('attached_name', nickname);
    }

    onClose();
  }, [getValues('attached_name')]);

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    await putAttachName({ id: chatRoomId, body: formData });
    refetchChat();

    onCloseModal();
  };

  return (
    <HalfModal
      id='nicknameModal'
      isOpen={isOpen}
      onClose={onCloseModal}
      title='ニックネームを変更'
      isInner
      footer={
        <AsyncButton
          className={components.button}
          data-color='black'
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          data-disbled={!isValid}
        >
          保存
        </AsyncButton>
      }
    >
      <TextInput
        id='attached_name'
        value={watch('attached_name') ?? ''}
        register={register('attached_name')}
        placeholder='ニックネーム'
        defaultValue={nickname}
        annotation={errors?.attached_name?.message}
        count={12}
        isInvalid={!isValid}
        onInput={(e) => {
          e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
        }}
      />
    </HalfModal>
  );
});
