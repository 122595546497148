import { memo } from 'react';
import { useAtomValue } from 'jotai';
import Female01 from '@/assets/coaching/like_female_01.png';
import Female02 from '@/assets/coaching/like_female_02.png';
import Male01 from '@/assets/coaching/like_male_01.png';
import Male02 from '@/assets/coaching/like_male_02.png';
import { SettingStep } from '@/components/features/coaching/SettingStep';
import { ModalSwipe } from '@/components/features/ModalSwipe';
import { BasicModal } from '@/components/styles/uis/BasicModal';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/coaching.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isMenu?: boolean;
};

export const LikeCoaching: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, isMenu } = props;

  const stepLength = isMenu ? 2 : 3;
  const { isMale } = useAtomValue(meFlagAtom);

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} viewName='how_to_use_like'>
      <ModalSwipe stepLength={stepLength}>
        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male01 : Female01} alt='お相手とマッチングする' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>お相手とマッチングする</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>「いいね!」では、あなたに興味のあるお相手が表示されます。</p>
              <p className={components['basic-text']}>
                お相手のプロフィールやメッセージを確認して、「マッチ」ボタンを押しましょう。
              </p>
              <p className={components['basic-text']}>マッチングが成立し、やりとりが開始します。</p>
            </div>
          </div>
        </li>
        <li>
          <div className={styles['img-wrapper']}>
            <img src={isMale ? Male02 : Female02} alt='質問付きいいね!を設定' />
          </div>
          <div className={styles.inner}>
            <p className={styles.title}>質問付きいいね!を設定</p>
            <div className={styles.block}>
              <p className={components['basic-text']}>
                質問付きいいね!を設定すると、設問に答えたお相手からのみ、いいね!が届くようになります。
              </p>
              <p className={components['basic-text']}>
                上手に活用することで、あなたと相性の良いお相手を厳選することができます。
              </p>
            </div>
          </div>
        </li>
        {!isMenu && <SettingStep />}
      </ModalSwipe>
      <div className={styles['button-wrapper']}>
        <button type='button' className={components.button} data-color='black' onClick={onClose}>
          {isMenu ? '閉じる' : 'さっそく使ってみる'}
        </button>
      </div>
    </BasicModal>
  );
});
