import { memo } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import kv from '@/assets/lp/questionLike/kv.png';
import step1 from '@/assets/lp/questionLike/step_01.png';
import step2 from '@/assets/lp/questionLike/step_02.png';
import step3 from '@/assets/lp/questionLike/step_03.png';
import { slideVariants } from '@/functions/constants/framerMotion';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/lp/questionLike.module.scss';
import account from '@/styles/pages/account.module.scss';

type Props = {
  onClose: () => void;
};

export const QuestionLikeLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  return (
    <>
      <TrackPageView viewName='lp_questionlike' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>質問付きいいね!とは</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.kv}>
              <img src={kv} alt='質問付きいいね!' />
            </div>

            <div className={styles.inner}>
              <div className={styles.section}>
                <h2 className={styles['section-title']}>質問付きいいね!とは？</h2>
                <p className={components['basic-text']}>
                  あなたが設定した質問に回答をしたお相手だけが、あなたに「いいね!」を送ることができる機能です。
                </p>
              </div>

              <div className={styles.section}>
                <h2 className={styles['section-title']}>メリット</h2>
                <ul className={styles['section-step']}>
                  <li>
                    <span className={styles['section-step-label']}>MERIT 1</span>
                    <h3 className={components['heading-1']}>価値観の合うお相手とマッチング</h3>
                    <p className={components['basic-text']}>あなたと価値観と合うお相手か、事前に知ることができます。</p>
                  </li>
                  <li>
                    <span className={styles['section-step-label']}>MERIT 2</span>
                    <h3 className={components['heading-1']}>質問を通して自己アピールできる</h3>
                    <p className={components['basic-text']}>
                      メッセージのやりとりをする前に、アピールや価値観を相手に伝えることができます。
                    </p>
                  </li>
                  <li>
                    <span className={styles['section-step-label']}>MERIT 3</span>
                    <h3 className={components['heading-1']}>受け取る「いいね!」を厳選できる</h3>
                    <p className={components['basic-text']}>
                      「いいね!」を受け取りすぎてお困りの場合は「質問付きいいね!」を設定することで、「いいね!」を厳選することができます。
                    </p>
                  </li>
                </ul>
              </div>

              <div className={styles.section}>
                <h2 className={styles['section-title']}>設定方法</h2>
                <ul className={styles['section-step']}>
                  <li>
                    <span className={styles['section-step-label']}>STEP 1</span>
                    <h3 className={components['heading-1']}>設定ページへ行く</h3>
                    <p className={components['basic-text']}>「質問付きいいね!」の設定は以下の2箇所からできます。</p>
                    <div className={styles['section-img']}>
                      <img src={step1} alt='設定ページへ行く' />
                    </div>
                  </li>
                  <li>
                    <span className={styles['section-step-label']}>STEP 2</span>
                    <h3 className={components['heading-1']}>質問を設定する</h3>
                    <p className={components['basic-text']}>
                      質問は最大3つまで設定ができます。必ず答えてもらいたい質問がある場合は、「回答必須」にチェックを入れましょう。
                    </p>
                    <div className={styles['section-img']}>
                      <img src={step2} alt='質問を設定する' />
                    </div>
                  </li>
                  <li>
                    <span className={styles['section-step-label']}>STEP 3</span>
                    <h3 className={components['heading-1']}>運営による審査を待つ</h3>
                    <p className={components['basic-text']}>
                      「質問付きいいね!」の公開には運営事務局の審査があります。承認された質問は自動的に公開されます。
                    </p>
                    <p className={clsx(components['basic-text'], components['mt-gutter'])}>
                      なお、不適切な内容での設定はできません。閲覧するお相手へ配慮をした質問を登録してください。
                    </p>
                    <div className={styles['section-img']}>
                      <img src={step3} alt='運営による審査を待つ' />
                    </div>
                  </li>
                </ul>
              </div>

              <div className={styles.precaution}>
                <h3 className={styles['precaution-heading']}>注意事項</h3>
                <div className={styles['precaution-text']}>
                  <p className={components['basic-text']}>
                    ・回答を必須にした場合、質問に回答したお相手のみがあなたに「いいね!」を送ることができます。
                  </p>
                  <p className={components['basic-text']}>
                    ・「質問付きいいね!」を設定することで、受け取る「いいね!」数が減ることがあります。
                  </p>
                  <p className={components['basic-text']}>
                    ・不適切な質問は運営審査で否認されますので、ご了承ください。
                  </p>
                </div>
              </div>

              <div className={styles['button-wrapper']}>
                <button type='button' className={components.button} onClick={onClose}>
                  質問付きいいね!を設定する
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
