import { memo, useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { useDeletePhrases, usePutPhrases } from '@/apis';
import { AsyncButton } from '@/components/features/AsyncButton';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useBasicModal, useSnackbar } from '@/functions/hooks';
import { UserPhrase } from '@/functions/types/phrase';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/messageTemplateModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  messageTemplates: string[];
  data?: UserPhrase[];
  refetch: () => void;
};

export const MessageTemplateModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, messageTemplates, data, refetch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [phrase, setPhrase] = useState<UserPhrase | null>(null);
  const [text, setText] = useState('');

  const { isMale } = useAtomValue(meFlagAtom);

  const { openSnackbar } = useSnackbar();
  const { onCloseModal, messageTemplateDeleteModal } = useBasicModal();

  const { putPhrases } = usePutPhrases();
  const { deletePhrases } = useDeletePhrases();

  const onCloseEdit = useCallback(() => {
    setIsEdit(false);
    setPhrase(null);
    setText('');
  }, []);

  const handleClickAddTemplate = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleClickEdit = useCallback((phrase: UserPhrase) => {
    setIsEdit(true);
    setPhrase(phrase);
    setText(phrase.message);
  }, []);

  const getOrder = useCallback(() => {
    if (phrase) {
      return phrase.order;
    }
    if (data) {
      return data.length;
    }

    return 0;
  }, [phrase, data]);

  const handleClickSave = useCallback(async () => {
    await putPhrases({
      phrase: {
        message: text,
        order: getOrder()
      }
    });

    refetch();
    onCloseEdit();
    openSnackbar({
      type: 'toast',
      text: '保存しました。'
    });
  }, [text, phrase]);

  const onDelete = useCallback(async () => {
    if (!phrase) return;

    await deletePhrases({ id: phrase.id });

    refetch();
    onCloseModal();
    onCloseEdit();
    openSnackbar({
      type: 'toast',
      text: '削除しました。',
      bottom: 80
    });
  }, [phrase]);

  const handleClickRemove = useCallback(() => {
    messageTemplateDeleteModal(onDelete);
  }, [phrase]);

  return (
    <HalfModal
      id='messageTemplateModal'
      isOpen={isOpen}
      onClose={isEdit ? onCloseEdit : onClose}
      title={isEdit ? `マイパターン${getOrder() + 1}` : 'テンプレート一覧'}
      isPrev={isEdit}
      onClickOther={
        phrase ? (
          <button type='button' onClick={handleClickRemove} className={styles['remove-button']}>
            削除
          </button>
        ) : null
      }
    >
      <AnimatePresence mode='wait' initial={false}>
        {!isEdit && (
          <motion.div key='list' initial='left' animate='enter' exit='left' variants={slideVariants}>
            {isMale && (
              <div className={styles.block}>
                <p className={styles['block-ttl']}>サンプル</p>
                <div className={styles['list-wrapper']}>
                  <ul className={styles.list}>
                    {messageTemplates.map((text, index) => (
                      <li key={text} className={styles.item}>
                        <div className={styles['item-header']}>
                          <span>サンプル{index + 1}</span>
                        </div>
                        <p>{text}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <div className={styles.block}>
              <p className={styles['block-ttl']}>マイパターン</p>
              {data?.length ? (
                <>
                  <div className={styles['list-wrapper']}>
                    <ul className={styles.list}>
                      {data.map((phrase, index) => (
                        <li key={phrase.id} className={styles.item}>
                          <div className={styles['item-header']}>
                            <span>マイパターン{index + 1}</span>
                            <button type='button' onClick={() => handleClickEdit(phrase)} aria-label='edit' />
                          </div>
                          <p>{phrase.message}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={styles['add-button-wrapper']} data-male={isMale} data-include>
                    <button
                      type='button'
                      className={components.button}
                      data-color={isMale ? 'clear' : 'black'}
                      onClick={handleClickAddTemplate}
                    >
                      <span className={styles['button-text']}>追加する</span>
                    </button>
                  </div>
                </>
              ) : (
                <button type='button' className={styles['add-button']} onClick={handleClickAddTemplate}>
                  <span>新規登録</span>
                </button>
              )}
            </div>
          </motion.div>
        )}

        {isEdit && (
          <motion.div
            key='edit'
            initial='right'
            animate='enter'
            exit='right'
            variants={slideVariants}
            className={components['large-basic-wrapper']}
          >
            <textarea value={text} onChange={(e) => setText(e.target.value)} className={styles.textarea} rows={10} />
            <div className={components['mt-gutter']}>
              <AsyncButton className={components.button} data-color='black' onClick={handleClickSave}>
                保存
              </AsyncButton>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </HalfModal>
  );
});
