import { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { usePostCheckDuplicateAppointments } from '@/apis';
import { usePutAppointments } from '@/apis/appointments/putAppointments';
import { AsyncButton } from '@/components/features/AsyncButton';
import { UserImg } from '@/components/styles/projects/UserImg';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { animateDuration } from '@/functions/constants/framerMotion';
import { formatDateRange, getDuplicateAlertText, getUserImage } from '@/functions/helpers';
import { useBasicModal, useSnackbar } from '@/functions/hooks';
import { Appointments } from '@/functions/types/appointments';
import { UserBasicInfo } from '@/functions/types/userBasicInfo';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/appointmentDetailModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  appointment: Appointments['appointment'];
  user: UserBasicInfo;
  refetch: () => void;
};

export const AppointmentDetailModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, appointment, user, refetch } = props;

  const [isConfirm, setIsConfirm] = useState(false);
  const [duplicateAlertText, setDuplicateAlertText] = useState<string | undefined>(undefined);

  const me = useAtomValue(meAtom);

  const { isBeginner } = useAtomValue(meFlagAtom);

  const { openSnackbar } = useSnackbar();
  const { appointmentDecideModal } = useBasicModal();

  const { putAppointments } = usePutAppointments();
  const { postCheckDuplicate } = usePostCheckDuplicateAppointments();

  const fetchDuplicateData = async () => {
    const data = await postCheckDuplicate({ id: appointment.id });
    const alertText = getDuplicateAlertText(data.board, data.appointment);
    setDuplicateAlertText(alertText);
  };

  const handleClickAgree = useCallback(async () => {
    await putAppointments({ id: appointment.id, body: { state: 'agreed' } });
    refetch();

    onClose();
    setTimeout(() => {
      appointmentDecideModal(
        <UserImg
          src={getUserImage(user, isBeginner)}
          size={72}
          isRoyal={!isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal')}
        />,
        <UserImg
          src={getUserImage(me, isBeginner)}
          size={72}
          isRoyal={!isBeginner && (me.patch_status === 'force_royal' || me.patch_status === 'royal')}
        />
      );
    }, animateDuration);
  }, [appointment]);

  const handleClickCancel = useCallback(
    async (isDecline: boolean) => {
      await putAppointments({ id: appointment.id, body: { state: 'cancelled' } });
      refetch();

      onClose();
      openSnackbar({
        type: 'toast',
        text: isDecline ? '約束をお断りしました。' : '約束をキャンセルしました。'
      });
    },
    [appointment]
  );

  useEffect(() => {
    if (appointment.state === 'pending' && appointment.send_user_id === user.id) {
      fetchDuplicateData();
    }
  }, [appointment]);

  return (
    <HalfModal id='appointmentDetailModal' isOpen={isOpen} onClose={onClose} title='約束の詳細' isInner>
      <div className={styles.user}>
        <UserImg
          src={getUserImage(user, isBeginner)}
          size={48}
          isRoyal={!isBeginner && (user.patch_status === 'force_royal' || user.patch_status === 'royal')}
        />
        <div className={styles['user-info']}>
          <span className={styles['user-name']}>{user.property.nickname}</span>
          <p className={styles['user-property']}>
            <span>{user.age}歳</span>
            <span>{user.property.residence_location}</span>
          </p>
        </div>
      </div>

      <div className={styles.appointment}>
        {appointment.state === 'agreed' && (
          <span className={styles['appointment-label']} data-status='agreed'>
            約束済み
          </span>
        )}
        {appointment.state === 'pending' && (
          <span className={styles['appointment-label']} data-status='pending'>
            約束確認待ち
          </span>
        )}
        <p className={styles['appointment-text']}>
          <span className='calendar' />
          {formatDateRange(appointment.start_at, appointment.end_at)}
        </p>
        <p className={styles['appointment-text']}>
          <span className='map' />
          {appointment.place}
        </p>
      </div>

      {duplicateAlertText && (
        <p className={clsx(styles['alert-text'], components['basic-text'])}>{duplicateAlertText}</p>
      )}

      <div className={styles['button-wrapper']}>
        {appointment.state === 'pending' && appointment.send_user_id === user.id ? (
          <>
            <AsyncButton className={components.button} data-color='black' onClick={() => handleClickAgree()}>
              確定
            </AsyncButton>
            <AsyncButton className={components.button} data-color='white' onClick={() => handleClickCancel(true)}>
              <span className={components['red-text']}>断る</span>
            </AsyncButton>
            <button type='button' className={components.button} data-color='clear' onClick={onClose}>
              <span className={components['black-text']}>あとで</span>
            </button>
          </>
        ) : (
          <>
            {isConfirm && (
              <>
                <AsyncButton className={components.button} data-color='red' onClick={() => handleClickCancel(false)}>
                  キャンセルを確定
                </AsyncButton>
                <div className={styles.caution}>
                  <p>お約束のキャンセルはお相手の了承を得てから行ってください。確定しますか？</p>
                </div>
              </>
            )}
            {!isConfirm && (
              <button type='button' className={components.button} data-color='red' onClick={() => setIsConfirm(true)}>
                お約束をキャンセル
              </button>
            )}
          </>
        )}
      </div>
    </HalfModal>
  );
});
