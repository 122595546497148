import { memo, useCallback, useState } from 'react';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';
import components from '@/styles/components/index.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selected: string;
  option: { [key: string]: string };
  onChange: (value: string) => void;
};

export const ResignReasonModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, selected, option, onChange } = props;

  const [temporarily, setTemporarily] = useState(selected);

  const handleClickSave = useCallback(() => {
    onChange(temporarily);
    onClose();
  }, [temporarily]);

  return (
    <HalfModal
      id='resignReasonModal'
      isOpen={isOpen}
      onClose={onClose}
      title='退会理由'
      footer={
        <button
          type='button'
          className={components.button}
          data-color='black'
          onClick={handleClickSave}
          disabled={!temporarily}
        >
          決定
        </button>
      }
    >
      <RadioGroup selected={temporarily} options={option} onChange={(e) => setTemporarily(e.target.value)} />
    </HalfModal>
  );
});
