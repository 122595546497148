import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet-async';
import { pushDataLayer } from '@/analytics/pushDataLayer';
import { titleAtom } from '@/contexts/atoms/title';
import { ViewName, viewEvent } from '@/functions/constants/viewEvent';

type Props = {
  viewName: ViewName;
};

/**
 * DataLayerにページビューイベントを送信するコンポーネント
 * ページビューイベントをトリガーにGTMではKARTEのviewイベントを送信している
 */
export const TrackPageView: React.FC<Props> = (props) => {
  const { viewName } = props;

  const [title, setTitle] = useAtom(titleAtom);

  const onChangeClientState = () => {
    // LSに保存されたタイトルとHelmetのタイトルが異なる場合のみデータレイヤーに送信
    if (title !== document.title) {
      console.log('🫷pushDataLayer', viewName, document.title);

      pushDataLayer({ event: 'pageView', viewName });

      setTitle(document.title);
    }
  };

  return (
    <Helmet onChangeClientState={onChangeClientState}>
      <title>paddy | {viewEvent[viewName]}</title>
    </Helmet>
  );
};
