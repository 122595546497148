import { memo, useCallback, useState, useEffect } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import InfiniteScroller from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { TrackPageView } from '@/analytics/TrackPageView';
import {
  useGetFootprints,
  usePostIdTalks,
  usePostTalks,
  usePostFootprintsReadAll,
  useGetChatRoom,
  usePostState
} from '@/apis';
import { ActivityUser } from '@/apis/activities/getFootprints';
import { activityKeys, badgeKeys, chatRoomKeys } from '@/apis/queryKeys';
import { ReactComponent as EmptyFootprint } from '@/assets/icons/empty_footprint.svg';
import { AsyncButton } from '@/components/features/AsyncButton';
import { ChanceTimeCompleteModal } from '@/components/features/modal/chanceTimeModal';
import { MatchModal } from '@/components/features/modal/MatchModal';
import { QuestionLikeModal } from '@/components/features/modal/QuestionLikeModal';
import { SwipeShow } from '@/components/features/show/SwipeShow';
import { UserImg } from '@/components/styles/projects/UserImg';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import { getDiffTime, checkNew, getUserImage } from '@/functions/helpers';
import { useBasicModal, useDisclosure, useSnackbar } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import { useChanceTime } from '@/functions/hooks/useChanceTime';
import { useMatchModal } from '@/functions/hooks/useMatchModal';
import { Chat } from '@/pages/Chat';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/footprint.module.scss';

type Props = {
  onClose: () => void;
};

export const Footprint: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const navigate = useNavigate();
  const [user, setUser] = useState<ActivityUser | null>(null);
  const [userIndex, setUserIndex] = useState<number>();
  const [userId, setUserId] = useState<number | null>(null);
  const [chatRoomId, setChatRoomId] = useState<number | null>(null);

  const me = useAtomValue(meAtom);

  const { isChanceTime } = useChanceTime();
  const { isMale, isPhoneVerify, basicPointLength, isAgeConfirm, isFreezed, isBeginner } = useAtomValue(meFlagAtom);

  const queryClient = useQueryClient();

  const questionLikeModal = useDisclosure();
  const chatModal = useDisclosure();
  const matchModal = useDisclosure();
  const chanceTimeCompleteModal = useDisclosure();

  const { openSnackbar } = useSnackbar();
  const { phoneVerificationModal, pointLackModal, isMeFreezedModal, isUserFreezedModal, ageConfirmationModal } =
    useBasicModal();

  const { fetchFootprints } = useGetFootprints();
  const { postIdTalks } = usePostIdTalks();
  const { postTalks } = usePostTalks();
  const { fetchChatRoom } = useGetChatRoom();
  const { postFootprintsReadAll } = usePostFootprintsReadAll();
  const { postState } = usePostState();

  const { isShownMatchModal } = useMatchModal();

  const { updatePointsCache } = useCache();

  useEffect(() => {
    (async () => {
      await postFootprintsReadAll();
    })();

    return () => {
      queryClient.invalidateQueries(badgeKeys.all);
    };
  }, []);

  const { isLoading, isFetching, hasNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: activityKeys.lists(),
    queryFn: async ({ pageParam = 1 }) => {
      const datas = await fetchFootprints({ page: pageParam });
      return datas;
    },
    getNextPageParam: (lastPage, allPage) => {
      if (lastPage.length === 0) return undefined;
      return allPage.length + 1;
    }
  });

  const refetch = useCallback(() => {
    queryClient.invalidateQueries(activityKeys.lists());
  }, []);

  const fetchChanceTime = useCallback(async () => {
    if (!isChanceTime) return;

    const { just_completed: justCompleted } = await postState();
    if (justCompleted) chanceTimeCompleteModal.open();
  }, [isChanceTime]);

  const { data: chatRoom } = useQuery({
    queryKey: chatRoomKeys.detail(chatRoomId),
    queryFn: async () => {
      if (!chatRoomId) return null;
      const chatRoom = await fetchChatRoom({ chatRoomId });

      return chatRoom;
    }
  });

  /**
   * ページを閉じてホームに遷移
   */
  const onCloseFootprint = useCallback(() => {
    onClose();
    navigate('/home');
  }, []);

  const openUserProfile = useCallback(
    (user: ActivityUser, userIndex: number) => {
      setUserIndex(userIndex);
      setUserId(user.id);
    },
    [data]
  );

  const onClickLike = useCallback(
    async (user: ActivityUser) => {
      if (isFreezed) {
        isMeFreezedModal();
      } else if (user.is_freezed) {
        isUserFreezedModal();
      } else if (isMale && !isPhoneVerify && !isChanceTime && basicPointLength === 0) {
        phoneVerificationModal();
      } else if (isMale && basicPointLength === 0 && !isChanceTime) {
        pointLackModal(
          <>「いいね!」を送るには1PTが必要です。ポイントをご購入の上、再度お試しください。</>,
          basicPointLength
        );
      } else {
        const chatRoomId = await postIdTalks({
          id: user.id,
          body: {
            like_from: 'activity'
          }
        });
        await postTalks({
          chat_room_id: chatRoomId,
          content: 'like',
          stamp_id: 1
        });

        updatePointsCache(1);
        refetch();
        fetchChanceTime();
        openSnackbar({
          type: 'toast',
          text: 'いいね!を送信しました。',
          bottom: 96
        });
      }
    },
    [isFreezed, isPhoneVerify, basicPointLength, isChanceTime]
  );

  const onClickQuestionLike = useCallback(
    (user: ActivityUser) => {
      if (isFreezed) {
        isMeFreezedModal();
      } else if (user.is_freezed) {
        isUserFreezedModal();
      } else if (!isPhoneVerify) {
        phoneVerificationModal();
      } else if (!isAgeConfirm) {
        ageConfirmationModal();
      } else if (user.na_text === 'question' && !isChanceTime && isMale && basicPointLength === 0) {
        pointLackModal(
          <>「質問付きいいね!」を送るには1PTが必要です。ポイントをご購入の上、再度お試しください。</>,
          basicPointLength
        );
      } else {
        setUser(user);
        questionLikeModal.open();
      }
    },
    [isFreezed, isPhoneVerify, isChanceTime, basicPointLength]
  );

  const onClickMatch = useCallback(
    async (user: ActivityUser) => {
      if (isFreezed) {
        isMeFreezedModal();
      } else if (user.is_freezed) {
        isUserFreezedModal();
      } else {
        await postTalks({
          chat_room_id: user.chat_room_id!,
          content: 'match',
          free_text: '♥ いいねありがとう'
        });

        refetch();
        setUser(user);

        if (isShownMatchModal(me.id)) {
          matchModal.open();
        }
      }
    },
    [isShownMatchModal(me.id), isFreezed, isPhoneVerify, isChanceTime, basicPointLength]
  );

  const onOpenChatModal = useCallback((chatRoomId: number) => {
    setChatRoomId(chatRoomId);
    chatModal.open();
  }, []);

  const onClickMessage = useCallback(
    async (user: ActivityUser) => {
      if (isFreezed) {
        isMeFreezedModal();
      } else if (user.is_freezed) {
        isUserFreezedModal();
      } else if (!isPhoneVerify) {
        phoneVerificationModal();
      } else {
        onOpenChatModal(user.chat_room_id!);
      }
    },
    [isFreezed, isPhoneVerify]
  );

  /**
   * チャットルームモーダルを閉じる
   */
  const onCloseChatModal = useCallback(() => {
    chatModal.close();
    setChatRoomId(null);
  }, []);

  const handleClickMatchMessage = useCallback(
    (chatRoomId: number) => {
      matchModal.close();
      setTimeout(() => {
        onOpenChatModal(chatRoomId);
      }, animateDuration);
    },
    [chatRoomId]
  );

  const onSubmitQuestionLike = useCallback(
    async (text: string) => {
      if (!user) return;
      const chatRoomId = await postIdTalks({ id: user.id, body: { like_from: 'activity' } });
      await postTalks({ chat_room_id: chatRoomId, content: 'question', free_text: text });

      refetch();
      fetchChanceTime();
      updatePointsCache(1);
      openSnackbar({
        type: 'toast',
        text: '質問付きいいね!を送信しました。',
        bottom: 96
      });
    },
    [user, isChanceTime]
  );

  const onSubmitQuestionMatch = useCallback(
    async (text: string) => {
      if (!user) return;

      const chatRoomId = await postIdTalks({ id: user.id, body: { like_from: 'activity' } });
      await postTalks({ chat_room_id: chatRoomId, content: 'question', free_text: text });

      if (isShownMatchModal(me.id)) matchModal.open();

      refetch();
      queryClient.invalidateQueries(badgeKeys.all);
    },
    [user, isShownMatchModal(me.id)]
  );

  return (
    <>
      <TrackPageView viewName='footprint' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>足あと</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            {isLoading && (
              <div className={styles['empty-wrapper']}>
                <LoadingSpinner />
              </div>
            )}

            {!isLoading && data?.pages.flatMap((v) => v).length ? (
              <>
                <div className={components['parent-wrapper']}>
                  <div className={styles.inner}>
                    <InfiniteScroller
                      pageStart={1}
                      hasMore={hasNextPage}
                      // @ts-ignore
                      loadMore={!isFetching ? fetchNextPage : () => null}
                      loader={
                        isFetching ? (
                          <div key={0} className={clsx(components['mt-gutter'], components['align-center'])}>
                            <LoadingSpinner size={24} />
                          </div>
                        ) : undefined
                      }
                      useWindow={false}
                    >
                      <ul>
                        {data.pages
                          .flatMap((v) => v)
                          .map((item, userIndex) => (
                            <li key={item.id} className={styles.card}>
                              <UserImg
                                id={item.user.id}
                                size={88}
                                onClick={() => openUserProfile(item.user, userIndex)}
                                src={getUserImage(item.user, isBeginner)}
                                lastActionTime={item.user.last_action_time}
                                isNew={checkNew(item.user.created_at)}
                                isRoyal={
                                  !isBeginner &&
                                  (item.user.patch_status === 'force_royal' || item.user.patch_status === 'royal')
                                }
                                isWarnig={item.user.is_warning}
                              />
                              <div className={styles['card-content']}>
                                <div className={styles['card-header']}>
                                  <span className={components['text-bold']}>{item.user.property.nickname}</span>
                                  <span className={(components['basic-text'], styles['card-property'])}>
                                    {item.user.age}歳 {item.user.property.residence_location}
                                  </span>
                                </div>
                                <div className={styles['card-text']}>
                                  <p className={(components['basic-text'], styles['card-ellipsis'])}>
                                    {item.user.property.one_word}
                                  </p>
                                  <p className={styles['card-date']}>{getDiffTime(item.created_at)}</p>
                                </div>
                                <div className={styles['button-wrapper']}>
                                  {item.user.na_text === 'like' && (
                                    <AsyncButton
                                      className={styles.button}
                                      data-size='submin'
                                      data-custom='true'
                                      onClick={() => onClickLike(item.user)}
                                    >
                                      <span className={styles['na-text']} data-status='like'>
                                        いいね!
                                      </span>
                                    </AsyncButton>
                                  )}
                                  {item.user.na_text === 'question' && (
                                    <button
                                      type='button'
                                      className={styles.button}
                                      data-size='submin'
                                      data-custom='true'
                                      onClick={() => onClickQuestionLike(item.user)}
                                    >
                                      <span className={styles['na-text']} data-status='question'>
                                        質問付きいいね!
                                      </span>
                                    </button>
                                  )}
                                  {item.user.na_text === 'match' && (
                                    <AsyncButton
                                      className={styles.button}
                                      data-size='submin'
                                      data-custom='true'
                                      onClick={() => onClickMatch(item.user)}
                                    >
                                      <span className={styles['na-text']} data-status='match'>
                                        マッチ
                                      </span>
                                    </AsyncButton>
                                  )}
                                  {item.user.na_text === 'question_match' && (
                                    <button
                                      type='button'
                                      className={styles.button}
                                      data-size='submin'
                                      data-custom='true'
                                      onClick={() => onClickQuestionLike(item.user)}
                                    >
                                      <span className={styles['na-text']} data-status='question'>
                                        マッチ
                                      </span>
                                    </button>
                                  )}
                                  {item.user.na_text === 'message' && (
                                    <AsyncButton
                                      className={styles.button}
                                      data-size='submin'
                                      data-color='pink'
                                      data-custom='true'
                                      onClick={() => onClickMessage(item.user)}
                                    >
                                      <span className={styles['na-text']} data-status='message'>
                                        メッセージ
                                      </span>
                                    </AsyncButton>
                                  )}
                                  {item.user.na_text === 'profile' && (
                                    <button
                                      type='button'
                                      className={styles.button}
                                      data-size='submin'
                                      data-custom='true'
                                      onClick={() => openUserProfile(item.user, userIndex)}
                                    >
                                      <span className={styles['na-text']} data-status='profile'>
                                        プロフィールをみる
                                      </span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </InfiniteScroller>
                  </div>
                </div>

                {user && (
                  <>
                    <QuestionLikeModal
                      isOpen={questionLikeModal.isOpen}
                      onClose={questionLikeModal.close}
                      userId={user.id}
                      userName={user.property.nickname}
                      userImg={getUserImage(user, isBeginner)}
                      questions={user.questions}
                      onSubmit={user.na_text === 'question_match' ? onSubmitQuestionMatch : onSubmitQuestionLike}
                      isMatch={user.na_text === 'question_match'}
                    />

                    <MatchModal
                      isOpen={matchModal.isOpen}
                      onClose={matchModal.close}
                      userImg={getUserImage(user, isBeginner)}
                      onOpenChat={() => handleClickMatchMessage(user.chat_room_id!)}
                      meImg={getUserImage(me, isBeginner)}
                    />
                  </>
                )}

                <AnimatePresence mode='wait' initial={false}>
                  {userId && (
                    <SwipeShow
                      userId={userId}
                      userIds={data?.pages.flatMap((d) => d.flatMap((v) => v.user.id)) ?? []}
                      setUserId={setUserId}
                      userIndex={userIndex}
                      fetchNextPage={!isFetching ? fetchNextPage : undefined}
                      onOpenChatRoom={() => chatModal.open()}
                      setChatRoomId={setChatRoomId}
                      from='footprint'
                      options={{ snackbarBottom: 20 }}
                    />
                  )}
                </AnimatePresence>

                <ChanceTimeCompleteModal
                  isOpen={chanceTimeCompleteModal.isOpen}
                  onClose={chanceTimeCompleteModal.close}
                />

                <AnimatePresence mode='wait' initial={false}>
                  {chatRoom && chatModal.isOpen && <Chat chatRoom={chatRoom} onClose={onCloseChatModal} />}
                </AnimatePresence>
              </>
            ) : (
              <div className={styles['empty-wrapper']}>
                <EmptyFootprint />
                <p className={clsx(components['heading-1'], components['mt-gutter'])}>足あとはありません。</p>
                <p className={clsx(components['basic-text'], components['align-center'])}>
                  気になるお相手を確認してみましょう。
                </p>
                <div className={styles['empty-button-wrapper']}>
                  <button type='button' className={components.button} data-color='black' onClick={onCloseFootprint}>
                    ホームへ
                  </button>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
});
