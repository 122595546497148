import { memo, useId } from 'react';
import styles from '@/styles/uis/tabSwitch.module.scss';

type ElementProps = JSX.IntrinsicElements['input'];

export type TabType = {
  value: string;
  label: string;
  badge?: number;
};

type Props = {
  name: string;
  tabs: TabType[];
  isActive: string;
} & ElementProps;

export const TabSwitch: React.FC<Props> = memo((_props) => {
  const { name, tabs, isActive, ...props } = _props;

  const id = useId();

  return (
    <div className={styles.wrapper}>
      {tabs.map((tab) => (
        <label key={tab.value} htmlFor={`${id}-${tab.value}`} data-unread={!!tab.badge} className={styles.tab}>
          <input
            id={`${id}-${tab.value}`}
            type='radio'
            name={`${id}-${name}-tabSwitch`}
            value={tab.value}
            checked={tab.value === isActive}
            {...props}
          />
          <span className={styles['tab-label']}>{tab.label}</span>
        </label>
      ))}
    </div>
  );
});
