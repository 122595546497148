import { ReactNode, memo, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { isLoadingAtom } from '@/contexts/atoms/isLoding';
import { animateDuration } from '@/functions/constants/framerMotion';

type ElementProps = JSX.IntrinsicElements['button'];

type Props = {
  children?: ReactNode;
} & ElementProps;

export const AsyncButton: React.FC<Props> = memo((_props) => {
  const { children, onClick, ...props } = _props;

  const [isLoadingLocalState, setIsLoadingLocalState] = useState(false);
  const [isLoadingGlobalState, setIsLoadingGlobalState] = useAtom(isLoadingAtom);
  const isLoadingRef = useRef(false);

  const isPromise = (value: any): value is Promise<any> => {
    return value && typeof value.then === 'function';
  };

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (onClick && !isLoadingRef.current && !isLoadingLocalState && !isLoadingGlobalState) {
      const result = onClick(event);

      if (isPromise(result)) {
        setIsLoadingLocalState(true);
        setIsLoadingGlobalState(true);
        isLoadingRef.current = true;
        try {
          await result;
        } finally {
          setTimeout(() => {
            setIsLoadingLocalState(false);
            setIsLoadingGlobalState(false);
            isLoadingRef.current = false;
          }, animateDuration);
        }
      }
    }
  };

  return isLoadingLocalState ? (
    <button type='button' {...props} data-loading={isLoadingLocalState}>
      <LoadingSpinner size={16} />
    </button>
  ) : (
    <button type='button' onClick={handleClick} {...props}>
      {children}
    </button>
  );
});
