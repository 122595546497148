import { memo, useCallback } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import InfiniteScroller from 'react-infinite-scroller';
import { TrackPageView } from '@/analytics/TrackPageView';
import { useGetHideUsers, useDeleteHideUser } from '@/apis';
import { userKeys } from '@/apis/queryKeys';
import { ReactComponent as Empty } from '@/assets/icons/empty_hide.svg';
import { UserImg } from '@/components/styles/projects/UserImg';
import { LoadingSpinner } from '@/components/styles/uis/LoadingSpinner';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { slideVariants } from '@/functions/constants/framerMotion';
import { getUserImage } from '@/functions/helpers';
import { useSnackbar, useBasicModal } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import { User } from '@/functions/types/chatRoom';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/config/hideUserList.module.scss';

type Props = {
  onClose: () => void;
};

export const HideUserList: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const { openSnackbar } = useSnackbar();
  const { onCloseModal, commonModal } = useBasicModal();

  const { updateUsersCache } = useCache();

  const { fetchHideUsers } = useGetHideUsers();
  const { deleteHideUser } = useDeleteHideUser();

  const { isBeginner } = useAtomValue(meFlagAtom);

  const { isLoading, isFetching, hasNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: userKeys.hide(),
    queryFn: async ({ pageParam = 1 }) => {
      const datas = await fetchHideUsers({ page: pageParam });
      return datas;
    },
    getNextPageParam: (lastPage, allPage) => {
      if (lastPage.length === 0) return undefined;

      return allPage.length + 1;
    }
  });

  const onSubmitUnhide = useCallback(async (userId: number) => {
    await deleteHideUser({ id: userId });

    updateUsersCache(userId, 'unhide');

    openSnackbar({
      type: 'toast',
      text: '非表示を解除しました。'
    });
    onCloseModal();
  }, []);

  const handleClickUnhide = useCallback((user: User) => {
    commonModal({
      title: `${user.property.nickname}さんの非表示を解除します。 よろしいですか？`,
      onClickLabel: '解除',
      onClick: () => onSubmitUnhide(user.id)
    });
  }, []);

  return (
    <>
      <TrackPageView viewName='hidden_userlist' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>非表示リスト</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            {isLoading && (
              <div className={styles['center-wrapper']}>
                <LoadingSpinner />
              </div>
            )}
            {!isLoading && data?.pages.flatMap((v) => v).length ? (
              <div className={styles.inner}>
                <InfiniteScroller
                  pageStart={1}
                  hasMore={hasNextPage}
                  // @ts-ignore
                  loadMore={!isFetching ? fetchNextPage : () => null}
                  loader={
                    isFetching ? (
                      <div key={0} className={clsx(components['mt-gutter'], components['align-center'])}>
                        <LoadingSpinner size={24} />
                      </div>
                    ) : undefined
                  }
                  useWindow={false}
                >
                  <ul>
                    {data.pages
                      .flatMap((v) => v)
                      .map((user) => (
                        <li key={user.id} className={styles.card}>
                          <UserImg id={user.id} src={getUserImage(user, isBeginner)} size={88} />
                          <div className={styles['card-content']}>
                            <div className={styles['card-header']}>
                              <p className={components['text-bold']}>{user.property.nickname}</p>
                              <span className={styles['card-property']}>
                                {user.age}歳 {user.property.residence_location}
                              </span>
                            </div>
                            {user.property.one_word && (
                              <p className={clsx(components['basic-text'], styles['card-text'])}>
                                {user.property.one_word}
                              </p>
                            )}
                            <div className={styles['button-wrapper']}>
                              <button
                                type='button'
                                className={styles.button}
                                data-color='black'
                                data-size='submin'
                                data-custom='true'
                                onClick={() => handleClickUnhide(user)}
                              >
                                非表示を解除
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </InfiniteScroller>
              </div>
            ) : (
              <div className={styles['center-wrapper']}>
                <Empty />
                <p className={styles['empty-text']}>非表示にしたお相手はいません。</p>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
});
