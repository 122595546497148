import { memo } from 'react';
import { motion } from 'framer-motion';
import { TrackPageView } from '@/analytics/TrackPageView';
import kv from '@/assets/lp/secretMode/kv.png';
import point1 from '@/assets/lp/secretMode/point_01.png';
import point2 from '@/assets/lp/secretMode/point_02.png';
import point3 from '@/assets/lp/secretMode/point_03.png';
import { slideVariants } from '@/functions/constants/framerMotion';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/lp/secretMode.module.scss';
import account from '@/styles/pages/account.module.scss';

type Props = {
  onClose: () => void;
};

export const SecretModeLp: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  return (
    <>
      <TrackPageView viewName='lp_secretmode' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>シークレットモードとは</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={styles.kv}>
              <img src={kv} alt='シークレットモード' />
            </div>

            <div className={styles.inner}>
              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <p className={styles['section-header-label']}>POINT 1</p>
                  <h2 className={styles['section-title']}>検索で表示されない</h2>
                </div>
                <p>
                  お相手の検索であなたが非表示になります。不特定多数のお相手からの閲覧がなくなり、「いいね!」が届かなくなります。
                </p>

                <div className={styles['section-img']}>
                  <img src={point1} alt='検索で表示されない' />
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <p className={styles['section-header-label']}>POINT 2</p>
                  <h2 className={styles['section-title']}>プロフィールが非公開に</h2>
                </div>
                <p>
                  あなたが「いいね!」したお相手とマッチング中のお相手以外、あなたのプロフィールを閲覧することできなくなります。
                </p>
                <p className={styles['section-sub-text']}>
                  ※シークレットモードをONにすると募集の新規投稿ができなくなります
                </p>
                <div className={styles['section-img']}>
                  <img src={point2} alt='プロフィールが非公開に' />
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles['section-header']}>
                  <p className={styles['section-header-label']}>POINT 3</p>
                  <h2 className={styles['section-title']}>足あとがつかない</h2>
                </div>
                <p>
                  お相手のプロフィールを閲覧しても足あとがつかなくなります。足あとを気にせず、お相手探しが可能になります。
                </p>
                <div className={styles['section-img']}>
                  <img src={point3} alt='足あとがつかない' />
                </div>
              </div>

              <div className={styles['button-wrapper']}>
                <button type='button' className={components.button} onClick={onClose}>
                  シークレットモードを設定する
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
