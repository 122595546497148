import { memo, useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { TrackPageView } from '@/analytics/TrackPageView';
import { CertificationStatus, StatusType } from '@/components/styles/projects/CertificationStatus';
import { Indicator } from '@/components/styles/uis/Indicator';
import { meAtom } from '@/contexts/atoms/me';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { slideVariants } from '@/functions/constants/framerMotion';
import { formatDate } from '@/functions/helpers';
import { useBasicModal, usePhoneVerify, useSubElement } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/status.module.scss';

const parentName = 'status';

type Props = {
  onClose: () => void;
};

export const Status: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const me = useAtomValue(meAtom);
  const { member, isMale, isBeginner, isFreezed, isPhoneVerify, isAgeConfirm } = useAtomValue(meFlagAtom);

  const { parentElements, handleOpenSubElement } = useSubElement();
  const { commonModal, phoneVerificationModal, isMeFreezedModal } = useBasicModal();
  const { handleOpenPhoneVerify } = usePhoneVerify();

  const isForceUser = useMemo(() => {
    return ['force_premium', 'force_royal'].includes(me.patch_status);
  }, []);

  const getAgeVerificationStatus = useCallback((): StatusType => {
    const { age_verification: ageVerification } = me;

    if (ageVerification.status === 'pass') {
      return 'complete';
    }
    if (ageVerification.status === 'inreview') {
      return 'progress';
    }
    return 'none';
  }, [me]);

  const getAgeVerificationLabel = useCallback(() => {
    const { age_verification: ageVerification } = me;

    if (ageVerification.status === 'pass') {
      return '確認済';
    }
    if (ageVerification.status === 'inreview') {
      return '審査中';
    }
    return '未認証';
  }, [me]);

  const getProfileImgStatus = useCallback((): StatusType => {
    const { top_image: topImage } = me;

    if (topImage.created_at && topImage.aasm_state === 'passed') {
      return 'complete';
    }
    if (topImage.created_at && topImage.aasm_state === 'before_reviewing') {
      return 'progress';
    }
    return 'none';
  }, [me]);

  const getProfileImgLabel = useCallback(() => {
    const { top_image: topImage } = me;

    if (topImage.created_at && topImage.aasm_state === 'passed') {
      return '登録済';
    }
    if (topImage.created_at && topImage.aasm_state === 'before_reviewing') {
      return '審査中';
    }
    return '未登録';
  }, [me]);

  const getProfileMovieStatus = useCallback((): StatusType => {
    const { profile_movie: movie } = me;

    if (movie.id && (movie.aasm_state === 'passed' || movie.aasm_state === 'mute_passed')) {
      return 'complete';
    }
    if (movie.id && movie.aasm_state === 'before_reviewing') {
      return 'progress';
    }
    return 'none';
  }, [me]);

  const getProfileMovieLabel = useCallback(() => {
    const { profile_movie: movie } = me;

    if (movie.id && (movie.aasm_state === 'passed' || movie.aasm_state === 'mute_passed')) {
      return '登録済';
    }
    if (movie.id && movie.aasm_state === 'before_reviewing') {
      return '審査中';
    }
    return '未登録';
  }, [me]);

  const getIncomeProofStatus = useCallback((): StatusType => {
    const { income_proof: incomeProof } = me;

    if (incomeProof && incomeProof.status === 'pass') {
      return 'complete';
    }
    if (incomeProof && incomeProof.status === 'inreview') {
      return 'progress';
    }
    return 'none';
  }, [me]);

  const getIncomeProofLabel = useCallback(() => {
    const { income_proof: incomeProof } = me;

    if (incomeProof && incomeProof.status === 'pass') {
      return '証明済';
    }
    if (incomeProof && incomeProof.status === 'inreview') {
      return '審査中';
    }

    return '未認証';
  }, [me]);

  const getAssetProofStatus = useCallback((): StatusType => {
    const { asset_proof: assetProof } = me;

    if (assetProof && assetProof.status === 'pass') {
      return 'complete';
    }
    if (assetProof && assetProof.status === 'inreview') {
      return 'progress';
    }
    return 'none';
  }, [me]);

  const getAssetProofLabel = useCallback(() => {
    const { asset_proof: assetProof } = me;

    if (assetProof && assetProof.status === 'pass') {
      return '証明済';
    }
    if (assetProof && assetProof.status === 'inreview') {
      return '審査中';
    }
    return '未認証';
  }, [me]);

  const getPremiumStatus = useCallback((): StatusType => {
    const plan = me.in_time_subscriptions.premium_subscription;

    if (plan && plan.renewal_at) {
      return 'complete';
    }
    if (plan && plan.unrenewed_at) {
      return 'stop';
    }
    if (isForceUser) {
      return 'complete';
    }

    return 'none';
  }, [me]);

  const getPremiumLabel = useCallback(() => {
    const plan = me.in_time_subscriptions.premium_subscription;

    if (plan && plan.renewal_at) {
      return '加入中';
    }
    if (plan && plan.unrenewed_at) {
      return '解約済';
    }
    if (isForceUser) {
      return '加入中';
    }

    return '未加入';
  }, [me]);

  const getRoyalStatus = useCallback((): StatusType => {
    const plan = me.in_time_subscriptions.royal_subscription;

    if (plan && plan.renewal_at) {
      return 'complete';
    }
    if (plan && plan.unrenewed_at) {
      return 'stop';
    }
    if (me.patch_status === 'force_royal') {
      return 'complete';
    }

    return 'none';
  }, [me]);

  const getRoyalLabel = useCallback(() => {
    const plan = me.in_time_subscriptions.royal_subscription;

    if (plan && plan.renewal_at) {
      return '加入中';
    }
    if (plan && plan.unrenewed_at) {
      return '解約済';
    }
    if (me.patch_status === 'force_royal') {
      return '加入中';
    }

    return '未加入';
  }, [me]);

  const handleOpenPremium = useCallback(() => {
    if (isFreezed) {
      isMeFreezedModal();
    } else if (!isPhoneVerify) {
      commonModal({
        title: '有料会員の加入には電話番号認証が必要です。',
        text: '安全にご利用いただくために電話番号認証を必須としています。',
        onCloseLabel: '閉じる'
      });
    } else if (!isAgeConfirm) {
      commonModal({
        title: '有料会員の加入には年齢確認が必要です。',
        text: '関連法規に基づき、この機能の使用には年齢確認を必須としています。',
        onCloseLabel: '閉じる'
      });
    } else {
      handleOpenSubElement('premiumLp', parentName);
    }
  }, [isPhoneVerify, isFreezed, isAgeConfirm]);

  const handleOpenRoyal = useCallback(() => {
    if (isFreezed) {
      isMeFreezedModal();
    } else {
      handleOpenSubElement('royalLp', parentName);
    }
  }, [isFreezed]);

  const getFormatDate = useCallback((date: string) => {
    return formatDate(new Date(date), { hideTime: true, hideWeekday: true });
  }, []);

  return (
    <>
      <TrackPageView viewName='membership_status' />
      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>会員ステータス</p>
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <ul className={components['parent-wrapper']} data-children-open={parentElements.second === parentName}>
              <li className={styles.card}>
                <div className={styles['card-header']}>
                  <p className={styles['card-title']}>電話番号認証</p>
                  <CertificationStatus
                    status={isPhoneVerify ? 'complete' : 'none'}
                    label={isPhoneVerify ? '認証済' : '未認証'}
                  />
                </div>
                <p className={components['basic-text']}>
                  サービスを安全にご利用いただくために、一部機能の使用には認証を必須としています。
                </p>
                {!isPhoneVerify && (
                  <div className={styles['card-button-wrapper']}>
                    <button
                      type='button'
                      className={components.button}
                      data-color='black'
                      data-size='submin'
                      onClick={() => (isMale ? phoneVerificationModal() : handleOpenPhoneVerify())}
                    >
                      認証する
                    </button>
                  </div>
                )}
              </li>

              <li className={styles.card}>
                <div className={styles['card-header']}>
                  <p className={styles['card-title']}>年齢確認</p>
                  <CertificationStatus status={getAgeVerificationStatus()} label={getAgeVerificationLabel()} />
                </div>
                <p className={components['basic-text']}>関連法規に基づき、一部機能の使用には確認を必須としています。</p>
                {!['pass', 'inreview'].includes(me.age_verification.status) && (
                  <div className={styles['card-button-wrapper']}>
                    <button
                      type='button'
                      className={components.button}
                      data-color='black'
                      data-size='submin'
                      onClick={() => handleOpenSubElement('ageVerificationLp', parentName)}
                    >
                      認証する
                    </button>
                  </div>
                )}
              </li>

              {!isMale && (
                <>
                  <li className={styles.card}>
                    <div className={styles['card-header']}>
                      <p className={styles['card-title']}>プロフィール画像</p>
                      <CertificationStatus status={getProfileImgStatus()} label={getProfileImgLabel()} />
                    </div>
                    <p className={components['basic-text']}>
                      あなたが写っている画像を登録しましょう。
                      <br />
                      登録することで、マッチング率が大幅にUPします。
                    </p>
                    {!(me.top_image.created_at || me.top_image.aasm_state !== 'passed') && (
                      <div className={styles['card-button-wrapper']}>
                        <button
                          type='button'
                          className={components.button}
                          data-color='black'
                          data-size='submin'
                          onClick={() => handleOpenSubElement('profile', parentName)}
                        >
                          登録する
                        </button>
                      </div>
                    )}
                  </li>

                  <li className={styles.card}>
                    <div className={styles['card-header']}>
                      <p className={styles['card-title']}>アピール動画</p>
                      <CertificationStatus status={getProfileMovieStatus()} label={getProfileMovieLabel()} />
                    </div>
                    <p className={components['basic-text']}>
                      あなたが写っている動画を登録しましょう。
                      <br />
                      登録することで、マッチング率が大幅にUPします。
                    </p>
                    {getProfileMovieLabel() === '未登録' && (
                      <div className={styles['card-button-wrapper']}>
                        <button
                          type='button'
                          className={components.button}
                          data-color='black'
                          data-size='submin'
                          onClick={() => handleOpenSubElement('profile', parentName)}
                        >
                          登録する
                        </button>
                      </div>
                    )}
                  </li>
                </>
              )}

              <li className={styles.card}>
                <div className={styles['card-header']}>
                  <p className={styles['card-title']}>プロフィール入力</p>
                  <CertificationStatus
                    status={me.profile_input_rate === 100 ? 'complete' : 'none'}
                    label={me.profile_input_rate === 100 ? '完了' : '未完了'}
                  />
                </div>
                <div className={styles['card-indicator-wrapper']}>
                  <div className={styles['card-indicator-text']}>
                    <p className={components['basic-text']}>
                      あなたの魅力をアピールしましょう。
                      <br />
                      入力を増やすことで、マッチング率が大幅にUPします。
                    </p>
                  </div>
                  <Indicator value={me.profile_input_rate} type='percentage' />
                </div>
                {me.profile_input_rate !== 100 && (
                  <div className={styles['card-button-wrapper']}>
                    <button
                      type='button'
                      className={components.button}
                      data-color='black'
                      data-size='submin'
                      onClick={() => handleOpenSubElement('profile', parentName)}
                    >
                      プロフィール編集
                    </button>
                  </div>
                )}
              </li>

              {isMale && (
                <>
                  {!isBeginner && (
                    <li className={styles.card}>
                      <div className={styles['card-header']}>
                        <p className={styles['card-title']}>年収証明</p>
                        <CertificationStatus status={getIncomeProofStatus()} label={getIncomeProofLabel()} />
                      </div>
                      <p className={components['basic-text']}>運営公認の証、年収証明済みバッチを贈呈します。</p>
                      {getIncomeProofStatus() === 'none' && (
                        <div className={styles['card-button-wrapper']}>
                          <button
                            type='button'
                            className={components.button}
                            data-color='black'
                            data-size='submin'
                            onClick={() => handleOpenSubElement('incomeProofLp', parentName)}
                          >
                            詳細をみる
                          </button>
                        </div>
                      )}
                    </li>
                  )}

                  {!isBeginner && (
                    <li className={styles.card}>
                      <div className={styles['card-header']}>
                        <p className={styles['card-title']}>資産証明</p>
                        <CertificationStatus status={getAssetProofStatus()} label={getAssetProofLabel()} />
                      </div>
                      <p className={components['basic-text']}>運営公認の証、資産証明済みバッチを贈呈します。</p>
                      {getAssetProofStatus() === 'none' && (
                        <div className={styles['card-button-wrapper']}>
                          <button
                            type='button'
                            className={components.button}
                            data-color='black'
                            data-size='submin'
                            onClick={() => handleOpenSubElement('assetProofLp', parentName)}
                          >
                            詳細をみる
                          </button>
                        </div>
                      )}
                    </li>
                  )}

                  <li className={styles.card}>
                    <div className={styles['card-header']}>
                      <p className={styles['card-title']}>有料会員</p>
                      <CertificationStatus status={getPremiumStatus()} label={getPremiumLabel()} />
                    </div>

                    {isForceUser ? (
                      <p className={components['basic-text']}>プランを運営より付与中です。</p>
                    ) : (
                      <p className={components['basic-text']}>お相手とメッセージし放題プラン。</p>
                    )}

                    {me.in_time_subscriptions.premium_subscription ? (
                      <ul className={styles['subscribe-list']}>
                        <li className={styles['subscribe-item']}>
                          <span className={styles['subscribe-heading']}>加入中のプラン</span>
                          <span>{me.in_time_subscriptions.premium_subscription.name}</span>
                        </li>
                        {me.in_time_subscriptions.premium_subscription.renewal_at ? (
                          <>
                            <li className={styles['subscribe-item']}>
                              <span className={styles['subscribe-heading']}>次の契約更新日</span>
                              <span>{getFormatDate(me.in_time_subscriptions.premium_subscription.renewal_at!)}</span>
                            </li>
                            <li className={styles['subscribe-item']}>
                              <span className={styles['subscribe-heading']}>次のPT付与数</span>
                              <span>{me.in_time_subscriptions.premium_subscription.next_point_info?.amount}PT</span>
                            </li>
                            <li className={styles['subscribe-item']}>
                              <span className={styles['subscribe-heading']}>次のPT付与日</span>
                              <span>
                                {getFormatDate(
                                  me.in_time_subscriptions.premium_subscription.next_point_info?.grant_day!
                                )}
                              </span>
                            </li>
                          </>
                        ) : (
                          <li className={styles['subscribe-item']}>
                            <span className={styles['subscribe-heading']}>有効期限</span>
                            <span>{getFormatDate(me.in_time_subscriptions.premium_subscription.expired_at!)}</span>
                          </li>
                        )}
                        <p className={`${components['basic-text']} ${styles['subscribe-text']}`}>
                          最新の購読状況の確認には以下をご覧ください。
                          <br />
                          iOSは
                          <a
                            href='https://support.apple.com/ja-jp/118428'
                            target='_blank'
                            rel='noreferrer'
                            style={{ textDecoration: 'underline' }}
                          >
                            こちら
                          </a>
                          ・Androidは
                          <a
                            href='https://support.google.com/googleplay/answer/7018481'
                            target='_blank'
                            rel='noreferrer'
                            style={{ textDecoration: 'underline' }}
                          >
                            こちら
                          </a>
                        </p>
                      </ul>
                    ) : (
                      member === 'free' && (
                        <div className={styles['card-button-wrapper']}>
                          <button
                            type='button'
                            className={components.button}
                            data-color='black'
                            data-size='submin'
                            onClick={handleOpenPremium}
                          >
                            詳細をみる
                          </button>
                        </div>
                      )
                    )}
                  </li>

                  {!isBeginner && (
                    <li className={styles.card}>
                      <div className={styles['card-header']}>
                        <p className={styles['card-title']}>ロイヤル会員</p>
                        <CertificationStatus status={getRoyalStatus()} label={getRoyalLabel()} />
                      </div>
                      {me.patch_status === 'force_royal' ? (
                        <p className={components['basic-text']}>プランを運営より付与中です。</p>
                      ) : (
                        <p className={components['basic-text']}>ワンランク上の使い心地を体感できるプラン。</p>
                      )}

                      {me.in_time_subscriptions.royal_subscription ? (
                        <ul className={styles['subscribe-list']}>
                          <li className={styles['subscribe-item']}>
                            <span className={styles['subscribe-heading']}>加入中のプラン</span>
                            <span>{me.in_time_subscriptions.royal_subscription.name}</span>
                          </li>
                          {me.in_time_subscriptions.royal_subscription.renewal_at ? (
                            <>
                              <li className={styles['subscribe-item']}>
                                <span className={styles['subscribe-heading']}>次の契約更新日</span>
                                <span>{getFormatDate(me.in_time_subscriptions.royal_subscription.renewal_at!)}</span>
                              </li>
                              <li className={styles['subscribe-item']}>
                                <span className={styles['subscribe-heading']}>次のPT付与数</span>
                                <span>{me.in_time_subscriptions.royal_subscription.next_point_info?.amount}PT</span>
                              </li>
                              <li className={styles['subscribe-item']}>
                                <span className={styles['subscribe-heading']}>次のPT付与日</span>
                                <span>
                                  {getFormatDate(
                                    me.in_time_subscriptions.royal_subscription.next_point_info?.grant_day!
                                  )}
                                </span>
                              </li>
                            </>
                          ) : (
                            <li className={styles['subscribe-item']}>
                              <span className={styles['subscribe-heading']}>有効期限</span>
                              <span>{getFormatDate(me.in_time_subscriptions.royal_subscription.expired_at!)}</span>
                            </li>
                          )}
                        </ul>
                      ) : (
                        member === 'premium' && (
                          <div className={styles['card-button-wrapper']}>
                            <button
                              type='button'
                              className={components.button}
                              data-color='black'
                              data-size='submin'
                              onClick={handleOpenRoyal}
                            >
                              詳細をみる
                            </button>
                          </div>
                        )
                      )}
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        </motion.div>
      </div>
    </>
  );
});
