import { memo, useCallback, useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { TrackPageView } from '@/analytics/TrackPageView';
import { usePostQuestions, usePutQuestions } from '@/apis';
import { meKeys } from '@/apis/queryKeys';
import Trash from '@/assets/icons/trash-black.svg';
import { AsyncButton } from '@/components/features/AsyncButton';
import { CheckBox } from '@/components/styles/uis/CheckBox';
import { TextInput } from '@/components/styles/uis/TextInput';
import { meAtom } from '@/contexts/atoms/me';
import { slideVariants } from '@/functions/constants/framerMotion';
import { useBasicModal, useSnackbar, useSubElement } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import account from '@/styles/pages/account.module.scss';
import styles from '@/styles/pages/mypage/question.module.scss';

const questionLength = 3;

const emptyValue = {
  content: 'other',
  other_text: '',
  require: false
};

const schema = z.object({
  questions: z.array(
    z.object({
      content: z.string(),
      other_text: z.string().max(20, '20文字以内で入力してください。'),
      require: z.boolean()
    })
  )
});

type Schema = z.infer<typeof schema>;

type Props = {
  onClose: () => void;
};

export const Question: React.FC<Props> = memo((props) => {
  const { onClose } = props;

  const { questions } = useAtomValue(meAtom);

  const { openSnackbar } = useSnackbar();
  const { onCloseModal, commonModal } = useBasicModal();
  const { parentElements, handleOpenSubElement } = useSubElement();

  const queryClient = useQueryClient();
  const { postQuestions } = usePostQuestions();
  const { putQuestions } = usePutQuestions();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {},
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  const { fields } = useFieldArray({
    control,
    name: 'questions'
  });

  const isFormValid = useMemo(() => {
    const questions = watch('questions');
    if (!questions) return true;

    const fields = questions.map((question) => {
      if (question.require) {
        return question.other_text && /\S/.test(question.other_text);
      }

      return !question.other_text || /\S/.test(question.other_text);
    });

    return fields.every((v) => v === true);
  }, [watch()]);

  useEffect(() => {
    if (questions.length) {
      const data = questions.map((question) => {
        return {
          content: 'other',
          other_text: question.aasm_state === 'rejected' ? '' : question.other_text,
          require: question.aasm_state === 'rejected' ? false : question.require
        };
      });

      setValue('questions', data);
    } else {
      const data = [...Array(questionLength)].map(() => {
        return emptyValue;
      });

      setValue('questions', data);
    }
  }, []);

  const isReviewingAll = useMemo(() => {
    if (!questions || questions.length === 0) {
      return false;
    }

    return questions.every((q) => q.aasm_state === 'reviewing');
  }, [questions]);

  const formValid = [isValid, isFormValid, !isReviewingAll].every((v) => v === true);

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    const formatFormData = formData.questions.map((q) => ({ ...q, other_text: q.other_text.trim() }));

    if (questions.length) {
      const data = questions.map((question, index) => {
        return {
          id: question.id,
          ...formatFormData[index]
        };
      });

      await putQuestions({ questions: data });
    } else {
      await postQuestions({ questions: formatFormData });
    }
    queryClient.invalidateQueries(meKeys.all);

    openSnackbar({
      type: 'popup',
      title: '質問付きいいね!を設定しました。',
      text: '審査完了後に公開されます。',
      bottom: 94
    });
  };

  const onReset = useCallback(() => {
    if (questions === null) {
      onCloseModal();
      return;
    }

    for (let i = 0; i < questionLength; i += 1) {
      if (questions[i]?.aasm_state !== 'reviewing') {
        setValue(`questions.${i}`, emptyValue);
      }
    }
    onCloseModal();
  }, [questions]);

  const handleClickReset = useCallback(() => {
    commonModal({
      title: '質問をすべてリセットしますか？',
      onClickLabel: 'リセット',
      onClick: onReset
    });
  }, [questions]);

  const getError = useCallback(
    (name: string) => {
      if (!errors.questions || errors.questions.length === 0) {
        return false;
      }

      return errors?.questions?.flatMap!((v) => v?.other_text).find((v) => v?.ref?.name === name);
    },
    [errors]
  );

  return (
    <>
      <TrackPageView viewName='questionlike_create' />

      <div className={account.submodal}>
        <motion.div
          initial='right'
          animate='enter'
          exit='right'
          variants={slideVariants}
          className={account['submodal-wrapper']}
        >
          <div className={account['submodal-header']}>
            <div className={account['submodal-header-inner']}>
              <button
                type='button'
                onClick={onClose}
                className={account['submodal-header-back-button']}
                aria-label='close'
              />
              <p className={account['submodal-header-title']}>質問付きいいね!設定</p>
              <button
                type='button'
                onClick={() => handleOpenSubElement('questionLikeLp', 'question')}
                className={styles['header-other-button']}
                aria-label='other'
              />
            </div>
          </div>

          <div className={account['submodal-contents']}>
            <div className={components['parent-wrapper']} data-children-open={parentElements.second === 'question'}>
              <div className={components.inner}>
                <ul className={components['border-list']}>
                  {fields.map((question, index) => (
                    <li key={question.id}>
                      {questions[index]?.aasm_state === 'reviewing' && (
                        <span className={styles['reviewing-label']}>審査中</span>
                      )}
                      <div
                        className={styles['input-header']}
                        data-reviewing={questions[index]?.aasm_state === 'reviewing'}
                      >
                        <p>{index + 1}つめの質問</p>
                        <Controller
                          name={`questions.${index}.require`}
                          control={control}
                          render={({ field }) => (
                            <CheckBox
                              selected={field.value}
                              onChange={field.onChange}
                              isReverse
                              disabled={questions[index]?.aasm_state === 'reviewing'}
                            >
                              <span className={components['text-small']}>回答必須</span>
                            </CheckBox>
                          )}
                        />
                      </div>
                      <Controller
                        name={`questions.${index}.other_text`}
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            value={field.value}
                            onChange={field.onChange}
                            placeholder='質問内容'
                            annotation={getError(field.name) ? errors.questions![index]?.other_text?.message : ' '}
                            count={20}
                            isInvalid={!!getError(field.name)}
                            icon={field.value.length === 0 || questions[index]?.aasm_state === 'reviewing' ? '' : Trash}
                            onClickIcon={() => setValue(`questions.${index}.other_text`, '')}
                            style={
                              questions[index]?.aasm_state === 'reviewing'
                                ? { opacity: 0.5, background: '#fff', border: '1px solid' }
                                : {}
                            }
                            disabled={questions[index]?.aasm_state === 'reviewing'}
                          />
                        )}
                      />
                    </li>
                  ))}
                </ul>

                <div className={styles['button-wrapper']}>
                  <div className={components['row-button-wrapper']}>
                    <button
                      type='button'
                      className={components.button}
                      data-color='white'
                      onClick={handleClickReset}
                      disabled={isReviewingAll}
                    >
                      リセット
                    </button>
                    <AsyncButton
                      className={components.button}
                      data-color='black'
                      onClick={handleSubmit(onSubmit)}
                      disabled={!formValid}
                      data-disabled={!formValid}
                    >
                      設定
                    </AsyncButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
});
