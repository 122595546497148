import { memo, useCallback, useMemo, useState } from 'react';
import { AsyncButton } from '@/components/features/AsyncButton';
import { UserImg } from '@/components/styles/projects/UserImg';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { TextInput } from '@/components/styles/uis/TextInput';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/qustionLikeModal.module.scss';

type QuestionType = {
  id: number;
  content: string;
  other_text: string;
  require: boolean;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (text: string) => Promise<void>;
  userId: number;
  userName: string;
  userImg: string;
  questions: QuestionType[];
  isMatch: boolean;
};

export const QuestionLikeModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, onSubmit, userName, userImg, questions, isMatch, userId } = props;

  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');

  const isValid = useMemo(() => {
    const fields = questions.map((question, index) => {
      if (question.require) {
        if (index === 0) {
          return !!answer1.length;
        }
        if (index === 1) {
          return !!answer2.length;
        }
        if (index === 2) {
          return !!answer3.length;
        }
      }

      return true;
    });

    return fields.every((v) => v === true);
  }, [questions, answer1, answer2, answer3]);

  /**
   * 質問と回答を結合してメッセージを生成
   * 例）<b>Q. 好きな食べ物は？</b><br>餃子<br><br><b>Q. 趣味は？</b><br>読書<br><br><b>Q. 好きな漫画は？</b><br>スラムダンク
   */
  const generatePostMessage = useCallback(() => {
    const getAnswer = (index: number) => {
      if (index === 0) {
        return answer1;
      }
      if (index === 1) {
        return answer2;
      }

      return answer3;
    };

    const text = questions.reduce((acc, cur, index) => {
      const questionAndAnswer = `<b>Q. ${cur.other_text}</b><br>${getAnswer(index) || '未回答'}`;
      return index !== questions.length - 1 ? `${acc}${questionAndAnswer}<br><br>` : `${acc}${questionAndAnswer}`;
    }, '');

    return text;
  }, [answer1, answer2, answer3, userId]);

  const handleSubmitQuestion = useCallback(async () => {
    await onSubmit(generatePostMessage());

    setAnswer1('');
    setAnswer2('');
    setAnswer3('');
    onClose();
  }, [answer1, answer2, answer3, onSubmit, generatePostMessage]);

  const handleClose = useCallback(() => {
    setAnswer1('');
    setAnswer2('');
    setAnswer3('');
    onClose();
  }, []);

  return (
    <HalfModal id='questionLikeModal' isOpen={isOpen} onClose={handleClose} title='質問付きいいね!' isInner>
      <p className={components['text-small']}>{userName}さんへの質問に回答していいね!を送りましょう。</p>
      <ul className={styles['question-list']}>
        {questions.map((question, i) => (
          <li key={question.id} className={styles['question-item']}>
            <p className={styles['question-heading']}>{i + 1}つめの質問</p>
            <div className={styles['question-header']}>
              <UserImg src={userImg} size={32} />
              {question.other_text}
            </div>
            {i === 0 && (
              <TextInput value={answer1} onChange={(e) => setAnswer1(e.target.value)} placeholder='あなたの回答' />
            )}
            {i === 1 && (
              <TextInput value={answer2} onChange={(e) => setAnswer2(e.target.value)} placeholder='あなたの回答' />
            )}
            {i === 2 && (
              <TextInput value={answer3} onChange={(e) => setAnswer3(e.target.value)} placeholder='あなたの回答' />
            )}
            {!question.require && <span className={styles['question-optional']}>任意</span>}
          </li>
        ))}
      </ul>
      <div className={styles['button-wrapper']}>
        <AsyncButton
          className={components.button}
          data-color='black'
          onClick={handleSubmitQuestion}
          disabled={!isValid}
          data-disabled={!isValid}
        >
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M15.0002 8.50436C15.6078 8.50436 16.1901 8.61552 16.7288 8.81911C17.2822 7.69244 17.637 6.43569 17.4497 5.236C17.1091 3.05355 15.2232 1.66675 13.1865 1.66675C11.1498 1.66675 9.17404 4.12831 9.17404 4.12831C9.17404 4.12831 7.27975 1.66905 5.1616 1.66675C3.04345 1.66445 1.3122 2.89653 0.898391 5.236C0.452811 7.75509 2.40303 10.4052 3.65692 11.7591C4.91081 13.1129 9.17404 17.0514 9.17404 17.0514C9.17404 17.0514 9.72229 16.5446 10.4826 15.833C10.1733 15.1662 10.0002 14.4201 10.0002 13.6326C10.0002 10.8003 12.2387 8.50436 15.0002 8.50436Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M15.0002 17.9061C17.3013 17.9061 19.1668 15.9928 19.1668 13.6326C19.1668 11.2724 17.3013 9.35906 15.0002 9.35906C12.699 9.35906 10.8335 11.2724 10.8335 13.6326C10.8335 14.7866 11.2795 15.8338 12.0043 16.6027L11.6668 18.3334L13.2597 17.5165C13.7894 17.7666 14.3789 17.9061 15.0002 17.9061ZM13.6668 12.6621C14.0002 12.6621 14.0002 12.6624 14.0002 12.6624L14.0003 12.6582L14.0007 12.6497C14.0009 12.6443 14.0013 12.6375 14.0019 12.6294C14.0039 12.6028 14.0081 12.5627 14.0166 12.5137C14.0339 12.4143 14.0678 12.2866 14.1324 12.1628C14.1963 12.0402 14.2868 11.9277 14.4158 11.8453C14.5425 11.7644 14.726 11.6996 15.0002 11.6996C15.2868 11.6996 15.4771 11.7557 15.605 11.8252C15.7318 11.8942 15.8158 11.9865 15.8738 12.0877C15.9967 12.3022 16.0002 12.5493 16.0002 12.6621C16.0002 12.8221 15.9244 12.9721 15.7845 13.1168C15.643 13.2631 15.4534 13.3847 15.2757 13.4766C14.9429 13.6488 14.6668 13.9822 14.6668 14.3933V14.7923H15.3335V14.3933C15.3335 14.2628 15.426 14.1181 15.5953 14.0306C15.8066 13.9213 16.066 13.7604 16.2763 13.5429C16.4881 13.3238 16.6668 13.0287 16.6668 12.6621L16.6668 12.6566C16.6669 12.5341 16.6671 12.1485 16.4598 11.7868C16.3512 11.5972 16.1851 11.4128 15.937 11.2779C15.6898 11.1435 15.3802 11.0685 15.0002 11.0685C14.6076 11.0685 14.2912 11.1635 14.0428 11.3222C13.7968 11.4794 13.6373 11.6865 13.5346 11.8834C13.4325 12.0791 13.3831 12.271 13.3588 12.4112C13.3464 12.482 13.3401 12.5418 13.3369 12.585C13.3353 12.6067 13.3345 12.6244 13.334 12.6374L13.3336 12.6535L13.3335 12.6587L13.3335 12.6607L13.3335 12.6618C13.3335 12.6618 13.3335 12.6621 13.6668 12.6621ZM15.0001 16.1967C15.2302 16.1967 15.4168 16.0201 15.4168 15.8022C15.4168 15.5843 15.2302 15.4077 15.0001 15.4077C14.77 15.4077 14.5835 15.5843 14.5835 15.8022C14.5835 16.0201 14.77 16.1967 15.0001 16.1967Z'
              fill='white'
            />
          </svg>
          {isMatch ? 'マッチ' : 'いいね!'}
        </AsyncButton>
      </div>
    </HalfModal>
  );
});
