import { memo, useCallback, useState } from 'react';
import { usePostIdTalks, usePostTalks } from '@/apis';
import { MessageTemplate } from '@/components/features/MessageTemplate';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { MessageInput } from '@/components/styles/uis/MessageInput';
import { useSnackbar } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import { LikeFrom } from '@/functions/types/likeFrom';
import components from '@/styles/components/index.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userId: number;
  userName: string;
  refetch: () => void;
  fetchChanceTime: () => void;
  likeFrom: LikeFrom;
};

export const MessageModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, userId, userName, refetch, fetchChanceTime, likeFrom } = props;

  const { postIdTalks } = usePostIdTalks();
  const { postTalks } = usePostTalks();

  const [messageText, setMessageText] = useState('');
  const [messageLoading, setMessageLoading] = useState(false);

  const { updateUsersCache, updatePointsCache, updateActivityCache } = useCache();

  const { openSnackbar } = useSnackbar();

  const handleSubmitMessage = useCallback(async () => {
    setMessageLoading(true);
    const chatRoomId = await postIdTalks({
      id: userId,
      body: {
        like_from: likeFrom
      }
    });

    await postTalks({
      chat_room_id: chatRoomId,
      content: 'message',
      free_text: messageText
    });

    refetch();
    updateUsersCache(userId, 'message');
    updatePointsCache(1);
    updateActivityCache(userId, 'user.na_text', 'profile');
    fetchChanceTime();
    setMessageText('');
    setMessageLoading(false);
    onClose();
    openSnackbar({
      type: 'toast',
      text: 'メッセージを送信しました。',
      bottom: 96
    });
  }, [messageText]);

  return (
    <HalfModal id='messageModal' isOpen={isOpen} onClose={onClose} title='メッセージ'>
      <div className={components['basic-wrapper']}>
        <MessageInput
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onSubmit={handleSubmitMessage}
          placeholder='メッセージを入力'
          disabled={messageText.trim().length === 0}
          isLoading={messageLoading}
          rows={3}
        />
      </div>
      <MessageTemplate userName={userName} setMessageText={(text) => setMessageText(text)} />
    </HalfModal>
  );
});
