import { memo, useCallback, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtomValue } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePostBoards, usePostCheckDuplicateBoards, usePutBoards } from '@/apis';
import { AsyncButton } from '@/components/features/AsyncButton';
import { AddImgModal } from '@/components/features/modal/AddImgModal';
import { CheckBox } from '@/components/styles/uis/CheckBox';
import { HalfModal } from '@/components/styles/uis/HalfModal';
import { RadioGroup } from '@/components/styles/uis/RadioGroup';
import { Select } from '@/components/styles/uis/Select';
import { TextareaInput } from '@/components/styles/uis/TextareaInput';
import { TextInput } from '@/components/styles/uis/TextInput';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { getObjValue, zeroPadding } from '@/functions/helpers';
import { useBasicModal, useDisclosure, useSnackbar } from '@/functions/hooks';
import { useCache } from '@/functions/hooks/useCache';
import { Schema, boardCreateSchema, defaultValue } from '@/functions/schemas/board/boardCreate';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/boardCreateModal.module.scss';

type ItemType = {
  week: string;
  day: number;
  value: string;
};

export type EditBoardData = {
  id: number;
  reservedDate: string;
  imgageUrl?: string;
  area: string;
  area_detail?: string;
  purpose: string;
  timeframe: string;
  freeText: string;
  hideWhenMatched: boolean;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dayItems: ItemType[];
  isMypage?: boolean;
  refetch?: () => void;
  boardData?: EditBoardData;
};

export const BoardCreateModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose, dayItems, isMypage, refetch, boardData } = props;

  const [previewImg, setPreviewImg] = useState('');

  const masterTypes = useAtomValue(masterTypesAtom);
  const { isMale } = useAtomValue(meFlagAtom);

  const { onCloseModal, commonModal } = useBasicModal();
  const { openSnackbar } = useSnackbar();

  const { createBoard } = usePostBoards();
  const { updateBoard } = usePutBoards();
  const { postCheckDuplicate } = usePostCheckDuplicateBoards();
  const { updatePointsCache } = useCache();

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: defaultValue,
    shouldFocusError: false,
    resolver: zodResolver(boardCreateSchema)
  });

  useEffect(() => {
    if (boardData) {
      setPreviewImg(boardData.imgageUrl ?? '');
      setValue('reserved_date', boardData.reservedDate);
      setValue('area', boardData.area);
      setValue('area_detail', boardData.area_detail ?? '');
      setValue('purpose', boardData.purpose);
      setValue('timeframe', boardData.timeframe);
      setValue('free_text', boardData.freeText);
      setValue('hide_when_matched', boardData.hideWhenMatched);
    } else {
      reset();
      setPreviewImg('');
    }
  }, [isOpen]);

  const appealImgModal = useDisclosure();
  const areaModal = useDisclosure();

  const getDateValue = useCallback((value: string, key: 'month' | 'date') => {
    const date = new Date(value);
    if (key === 'month') {
      return zeroPadding(date.getMonth() + 1, 2);
    }
    return zeroPadding(date.getDate(), 2);
  }, []);

  const setImg = useCallback((img: string | Blob) => {
    const reader = new FileReader();
    reader.onload = () => {
      const res = reader.result;
      if (res && typeof res === 'string') {
        setPreviewImg(res);
      }
    };

    reader.readAsDataURL(img as Blob);
    setValue('image', img);
  }, []);

  const onDeleteImg = useCallback(async () => {
    setPreviewImg('');
    openSnackbar({
      type: 'toast',
      text: '画像を削除しました。'
    });
    appealImgModal.close();
  }, []);

  const onSubmit: SubmitHandler<Schema> = async (formData) => {
    onCloseModal();

    try {
      await createBoard(formData);
      if (refetch) {
        refetch();
      }
      onClose();
      openSnackbar({
        type: 'popup',
        title: '募集を投稿しました。',
        text: '審査完了後に公開されます。',
        bottom: isMypage ? 16 : 152
      });
      updatePointsCache(10);
    } catch (err: any) {
      commonModal({ title: err.data.error_title });
    }
  };

  const onUpdate: SubmitHandler<Schema> = useCallback(
    async (formData) => {
      onCloseModal();
      try {
        await updateBoard({
          id: boardData?.id!,
          body: {
            reserved_date: formData.reserved_date,
            timeframe: formData.timeframe,
            area: formData.area,
            purpose: formData.purpose,
            hide_when_matched: formData.hide_when_matched
          }
        });
        if (refetch) {
          refetch();
        }
        onClose();
        openSnackbar({
          type: 'toast',
          text: '更新しました。',
          bottom: 16
        });
      } catch (err: any) {
        commonModal({ title: err.data.error_title });
      }
    },
    [boardData]
  );

  const handleCheckDuplicate = async (params: { id?: number; reserved_date: string; timeframe: string }) => {
    const { board, appointment } = await postCheckDuplicate(params);

    const submitText = boardData ? '更新' : '投稿';

    if (board) {
      commonModal({
        title: '他の募集と日時が重複しています。',
        text: '同日時に他の募集があります。日付もしくは時間帯を変更してください。'
      });
      return true;
    }

    if (appointment) {
      commonModal({
        title: 'お約束の日と重複しています。',
        text: `同日の約束があります。予定の重複にお気をつけください。それでも${submitText}しますか？`,
        onCloseLabel: 'キャンセル',
        onClickLabel: `${submitText}する`,
        onClick: boardData ? handleSubmit(onUpdate) : handleSubmit(onSubmit)
      });
      return true;
    }

    return false;
  };

  const handleClickCreate = useCallback(async () => {
    const isDuplicate = await handleCheckDuplicate({
      reserved_date: getValues('reserved_date'),
      timeframe: getValues('timeframe')
    });
    if (isDuplicate) return;

    commonModal({
      title: '募集を投稿しますか？',
      text: '投稿後は、自由記述項目と画像の編集ができません。よろしいですか？',
      onCloseLabel: 'キャンセル',
      onClickLabel: '投稿する',
      onClick: handleSubmit(onSubmit)
    });
  }, [boardData]);

  const handleClickEdit = useCallback(async () => {
    const isDuplicate = await handleCheckDuplicate({
      id: boardData?.id,
      reserved_date: getValues('reserved_date'),
      timeframe: getValues('timeframe')
    });
    if (isDuplicate) return;

    commonModal({
      title: '内容を更新しますか？',
      text: '編集内容を保存します。よろしいですか？',
      onCloseLabel: 'キャンセル',
      onClickLabel: '更新する',
      onClick: handleSubmit(onUpdate)
    });
  }, [boardData]);

  return (
    <>
      <HalfModal
        id='boardCreateModal'
        isOpen={isOpen}
        onClose={onClose}
        title='募集投稿'
        isInner
        viewName='boards_create'
      >
        <div className={components['gap-wrapper-wide']}>
          <div>
            <p className={components['text-bold']}>会いたい日</p>
            <div className={styles['radio-list-wrapper']}>
              <ul className={styles['radio-list']}>
                {dayItems.map((item, i) => (
                  <li key={item.value} className={styles['radio-item']}>
                    <label htmlFor={item.value}>
                      <input id={item.value} type='radio' value={item.value} {...register('reserved_date')} />
                      {i === 0 && '今日'}
                      {`${getDateValue(item.value, 'month')}/${getDateValue(item.value, 'date')}(${item.week})`}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles['detail-wrapper']}>
            <div
              className={styles['appeal-img']}
              data-disabled={!!boardData}
              data-empty={boardData && !previewImg}
              data-noimg={!previewImg}
            >
              {previewImg && <img src={previewImg} alt='' />}
              {!previewImg && !boardData && (
                <>
                  <p className={components['gray-text']}>アピール画像</p>
                  <p className={components['sub-text']}>お顔やスタイル、雰囲気がわかるお写真</p>
                </>
              )}
              {!boardData && (
                <>
                  <button
                    type='button'
                    className={styles['add-button']}
                    onClick={appealImgModal.open}
                    aria-label='appeal-img'
                  />
                  <span className={styles['appeal-img-label']}>任意</span>
                </>
              )}
            </div>
            <div className={styles['detail-inner']}>
              <div className={components['gap-wrapper-wide']}>
                <Select
                  value={getObjValue(masterTypes.board_areas, watch('area'))}
                  onClick={areaModal.open}
                  placeholder='エリア'
                />
                <TextInput
                  value={watch('area_detail')!}
                  register={register('area_detail')}
                  placeholder='場所（任意）'
                  annotation={'area_detail' in { ...errors } ? errors.area_detail?.message : '例）新宿・渋谷近辺'}
                  count={16}
                  isInvalid={'area_detail' in { ...errors }}
                  disabled={!!boardData}
                />
                <div>
                  <p className={components['gray-text']}>目的</p>
                  <div className={styles['radio-list-wrapper']}>
                    <ul className={styles['radio-list']}>
                      {Object.entries(masterTypes.board_purposes).map(([key, value]) => (
                        <li key={key} className={styles['radio-item']}>
                          <label htmlFor={key}>
                            <input id={key} type='radio' value={key} {...register('purpose')} />
                            {value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div>
                  <p className={components['gray-text']}>時間帯</p>
                  <div className={styles['radio-list-wrapper']}>
                    <ul className={styles['radio-list']}>
                      {Object.entries(masterTypes.board_timeframes).map(([key, value]) => (
                        <li key={key} className={styles['radio-item']}>
                          <label htmlFor={key}>
                            <input id={key} type='radio' value={key} {...register('timeframe')} />
                            {value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <TextareaInput
                  value={watch('free_text')}
                  register={register('free_text')}
                  rows={3}
                  placeholder='伝えたいことやアピール'
                  annotation={
                    'free_text' in { ...errors } ? '140文字以内で入力してください' : '例）お食事一緒にいきたいです！'
                  }
                  count={140}
                  isInvalid={'free_text' in { ...errors }}
                  disabled={!!boardData}
                />
              </div>
            </div>
          </div>
          <CheckBox
            selected={watch('hide_when_matched')}
            onChange={() => setValue('hide_when_matched', !getValues('hide_when_matched'))}
            isReverse
          >
            <p className={components['basic-text']}>マッチング中のお相手には表示しない</p>
          </CheckBox>
          {boardData ? (
            <AsyncButton
              className={components.button}
              onClick={handleClickEdit}
              data-disabled={!isValid}
              disabled={!isValid}
            >
              内容を更新
            </AsyncButton>
          ) : (
            <AsyncButton
              className={components.button}
              onClick={handleClickCreate}
              data-disabled={!isValid}
              disabled={!isValid}
            >
              募集を投稿
              {isMale && <span className={styles['create-button-point']}>10PT</span>}
            </AsyncButton>
          )}
          <div className={styles['annotation-block']}>
            <p>※募集は最大3つまで作成可能です。</p>
            <p>※募集は設定した日にちをすぎると自動で削除されます。</p>
            {!boardData && isMale && <p>※募集作成に10PT消費します。</p>}
            <p>※公開後は自由記述項目と画像の編集ができません。</p>
            <p>※内容の審査があるため、反映までにお時間を頂く場合があります。</p>
            <p>
              ※メールアドレス、LINE、カカオやその他SNSのID、電話番号などあなたを特定できる個人情報は送信できません。
            </p>
            <p>※健全なサービスを運営する目的で運営が募集内容を削除する場合があります。</p>
          </div>
        </div>
      </HalfModal>

      <HalfModal
        id='areaModal'
        isOpen={areaModal.isOpen}
        onClose={areaModal.close}
        title='エリア'
        footer={
          <button
            type='button'
            className={components.button}
            data-color='black'
            onClick={areaModal.close}
            disabled={!watch('area')}
          >
            決定
          </button>
        }
      >
        <RadioGroup selected={watch('area')} options={masterTypes.board_areas} register={register('area')} />
      </HalfModal>

      <AddImgModal
        from='board'
        setImg={setImg}
        isOpen={appealImgModal.isOpen}
        onClose={appealImgModal.close}
        imgId={previewImg ? 1 : undefined}
        onDelete={onDeleteImg}
      />
    </>
  );
});
