import { memo, useCallback, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useDeleteMe } from '@/apis';
import HeaderImg from '@/assets/lp/resign/img_header.png';
import FemaleSecretImg from '@/assets/lp/resign/img_secret_female.png';
import MaleSecretImg from '@/assets/lp/resign/img_secret_male.png';
import { AsyncButton } from '@/components/features/AsyncButton';
import { ResignReasonModal } from '@/components/features/modal/ResignReasonModal';
import { CheckBox } from '@/components/styles/uis/CheckBox';
import { FullModal } from '@/components/styles/uis/FullModal';
import { Select } from '@/components/styles/uis/Select';
import { TextareaInput } from '@/components/styles/uis/TextareaInput';
import { masterTypesAtom } from '@/contexts/atoms/masterTypes';
import { meFlagAtom } from '@/contexts/atoms/meFlag';
import { animateDuration, slideVariants } from '@/functions/constants/framerMotion';
import { getObjValue } from '@/functions/helpers';
import { useBasicModal, useDisclosure, useStep, useSubElement } from '@/functions/hooks';
import components from '@/styles/components/index.module.scss';
import styles from '@/styles/features/modal/resignModal.module.scss';

const schema = z.object({
  reason: z.string(),
  reasonDetail: z.string().max(500, '500文字以内で入力してください。').nonempty('退会理由を入力してください。'),
  kaizen: z.string().max(500, '500文字以内で入力してください。'),
  function: z.string().max(500, '500文字以内で入力してください。'),
  etc: z.string()
});

type Schema = z.infer<typeof schema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ResignModal: React.FC<Props> = memo((props) => {
  const { isOpen, onClose } = props;

  const { step, setStep, isNext, nextStep, backStep } = useStep();
  const [dataCheck, setDataCheck] = useState(false);
  const [planCheck, setPlanCheck] = useState(false);
  const [refundCheck, setRefundCheck] = useState(false);
  const [reregisterCheck, setReregisterCheck] = useState(false);
  const [handoverCheck, setHandoverCheck] = useState(false);

  const { basicPointLength } = useAtomValue(meFlagAtom);
  const { isMale } = useAtomValue(meFlagAtom);
  const { unsubscribe_reasons: unsubscribeReasons } = useAtomValue(masterTypesAtom);

  const resignReasonModal = useDisclosure();
  const { resignConfirmModal } = useBasicModal();
  const { handleOpenSubElement } = useSubElement();

  const { deleteMe } = useDeleteMe();

  const {
    getValues,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {
      reason: '',
      reasonDetail: '',
      kaizen: '',
      function: '',
      etc: ''
    },
    shouldFocusError: false,
    resolver: zodResolver(schema)
  });

  const formValid = useMemo(() => {
    if (isMale) {
      return [isValid, dataCheck, planCheck, refundCheck, reregisterCheck, handoverCheck].every((v) => v);
    }

    return [isValid, dataCheck, reregisterCheck, handoverCheck].every((v) => v);
  }, [isValid, dataCheck, planCheck, refundCheck, reregisterCheck, handoverCheck]);

  const unsubscribeReasonsOption = (unsubscribeReasons as never as { name: string; value: number }[]).reduce(
    (acc, cur) => {
      // @ts-ignore
      acc[cur.value] = cur.name;
      return acc;
    },
    {}
  );

  const onClickSecretMode = useCallback(() => {
    onClose();

    setTimeout(() => {
      setStep(1);
      handleOpenSubElement('secretMode', 'config');
    }, animateDuration);
  }, []);

  const onSubmit: SubmitHandler<Schema> = useCallback(async (formData) => {
    await deleteMe(formData);
  }, []);

  const handleClickResign = useCallback(async () => {
    resignConfirmModal(handleSubmit(onSubmit));
  }, []);

  return (
    <FullModal id='resignModal' isOpen={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <button
            type='button'
            className={styles['close-button']}
            onClick={step === 1 ? onClose : backStep}
            aria-label='close'
            data-prev={step !== 1}
          />
          <p>退会</p>
        </div>
        <AnimatePresence mode='wait' initial={false} custom={isNext}>
          {step === 1 && (
            <>
              <motion.div key='step1' initial='left' animate='enter' exit='left' variants={slideVariants}>
                <div className={styles.contents}>
                  <div className={styles.inner}>
                    <p className={components['basic-text']}>paddyをご利用いただき、誠にありがとうございます。</p>
                    <p className={components['basic-text']}>退会につきまして、以下の注意事項を必ずご確認ください。</p>
                    <hr />
                    <h2 className={components['heading-1']}>注意事項</h2>
                    {isMale && (
                      <div className={styles['point-wrapper']}>
                        <span className={styles['point-text']}>残ポイント</span>
                        <span className={styles['point-length']}>
                          <span className={components['text-bold']}>{basicPointLength ?? 0}</span>
                          PT
                        </span>
                      </div>
                    )}
                    <ul className={styles['caution-list']}>
                      <li>
                        <p>「いいね!」やメッセージ・ポイントなど、あらゆるデータが削除されます。</p>
                      </li>
                      <li>
                        <p>退会後、一定期間再登録ができません。</p>
                      </li>
                      <li>
                        <p>再登録しても過去のデータの引き継ぎはできません。</p>
                      </li>
                    </ul>
                    <hr />
                    <h2 className={components['heading-1']}>退会手続き</h2>
                    <p className={components['basic-text']}>
                      注意事項をご確認いただいた上で、退会をご希望の場合は、以下より手続きにお進みください。
                    </p>
                    <div className={styles['select-wrapper']}>
                      <Select
                        value={getObjValue(unsubscribeReasonsOption, getValues('reason'))}
                        onClick={resignReasonModal.open}
                        placeholder='退会理由'
                      />
                    </div>
                    <div className={styles['button-wrapper']}>
                      <button type='button' className={components.button} data-color='white' onClick={onClose}>
                        退会をやめる
                      </button>
                      <button
                        type='button'
                        className={components.button}
                        data-color='black'
                        onClick={nextStep}
                        disabled={!getValues('reason')}
                      >
                        退会手続きに進む
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>

              <ResignReasonModal
                isOpen={resignReasonModal.isOpen}
                onClose={resignReasonModal.close}
                selected={getValues('reason')}
                option={unsubscribeReasonsOption}
                onChange={(val) => setValue('reason', val)}
              />
            </>
          )}

          {step === 2 && (
            <motion.div
              key='step2'
              custom={isNext}
              initial='customRight'
              animate='enter'
              exit='customLeft'
              variants={slideVariants}
            >
              <div className={styles.contents}>
                <img src={HeaderImg} alt='ご案内' />
                <div className={styles.inner}>
                  <h2 className={components['heading-1']} data-border>
                    シークレットモードを
                    <br />
                    おやすみしてみませんか？
                  </h2>
                  <div className={styles['img-wrapper']}>
                    <img src={isMale ? MaleSecretImg : FemaleSecretImg} alt='お相手から非表示に' />
                  </div>
                  <div className={styles['text-wrapper']}>
                    <p className={components['basic-text']}>
                      シークレットモードを設定すると、お相手の検索にあなたが表示されなくなります。
                    </p>
                    <p className={components['basic-text']}>
                      新たに「いいね!」やメッセージが届かなくなるので、おやすみに適した機能です。
                    </p>
                    <p className={components['basic-text']}>
                      paddyおやすみ期間は、シークレットモードをご活用ください。
                    </p>
                  </div>
                  <div className={styles['button-wrapper']}>
                    <button type='button' className={components.button} data-color='black' onClick={onClickSecretMode}>
                      シークレットモードを設定
                    </button>
                  </div>
                  <h2 className={components['heading-1']} data-border style={{ marginTop: '48px' }}>
                    それでも退会される場合
                  </h2>
                  <div className={styles['text-wrapper']}>
                    <p className={components['basic-text']}>以下をご確認の上、お進みください。</p>
                  </div>
                  <ul className={styles['caution-list']}>
                    <li>
                      <p>退会すると「いいね!」やメッセージなどすべてのデータが消去されます。</p>
                    </li>
                  </ul>
                  <div className={styles['button-wrapper']}>
                    <button type='button' className={components.button} data-color='white' onClick={nextStep}>
                      退会手続きに進む
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          )}

          {step === 3 && (
            <motion.div key='step3' initial='right' animate='enter' exit='right' variants={slideVariants}>
              <div className={styles.contents}>
                <div className={styles.inner}>
                  <p className={components['text-bold']}>注意事項をご確認の上、チェックをお願いします。</p>
                  <div className={styles['check-wrapper']}>
                    <CheckBox selected={dataCheck} onChange={(e) => setDataCheck(e.target.checked)} isReverse>
                      <p className={components['text-small']}>
                        「いいね!」やメッセージなど、あらゆるデータが削除されます。
                      </p>
                    </CheckBox>
                    {isMale && (
                      <>
                        <CheckBox selected={planCheck} onChange={(e) => setPlanCheck(e.target.checked)} isReverse>
                          <p className={components['text-small']}>すべての有料プランを解約しました。</p>
                        </CheckBox>
                        <CheckBox selected={refundCheck} onChange={(e) => setRefundCheck(e.target.checked)} isReverse>
                          <p className={components['text-small']}>
                            有料プランの解約を行わず、退会後に請求が続いた場合もご返金はできません。
                          </p>
                        </CheckBox>
                      </>
                    )}
                    <CheckBox
                      selected={reregisterCheck}
                      onChange={(e) => setReregisterCheck(e.target.checked)}
                      isReverse
                    >
                      <p className={components['text-small']}>退会後、一定期間再登録ができません。</p>
                    </CheckBox>
                    <CheckBox selected={handoverCheck} onChange={(e) => setHandoverCheck(e.target.checked)} isReverse>
                      <p className={components['text-small']}>再登録しても過去のデータの引き継ぎはできません。</p>
                    </CheckBox>
                  </div>
                  {isMale && (
                    <>
                      <ul className={styles['caution-list']}>
                        <li>
                          <p>退会するとポイントも失効します</p>
                        </li>
                      </ul>
                      <div className={styles['point-wrapper']} style={{ marginTop: '12px' }}>
                        <span className={styles['point-text']}>残ポイント</span>
                        <span className={styles['point-length']}>
                          <span className={components['text-bold']}>{basicPointLength ?? 0}</span>
                          PT
                        </span>
                      </div>
                    </>
                  )}
                  <hr />

                  <div className={styles.form}>
                    <div className={styles['form-item']}>
                      <div className={styles['form-header']}>
                        <p>退会理由をご記入ください。</p>
                        <span className={styles['form-label']} data-required='true'>
                          必須
                        </span>
                      </div>
                      <TextareaInput
                        id='reasonDetail'
                        value={watch('reasonDetail')}
                        register={register('reasonDetail')}
                        annotation={'reasonDetail' in { ...errors } ? errors.reasonDetail?.message : ''}
                        count={500}
                        isInvalid={'reasonDetail' in { ...errors }}
                      />
                    </div>
                    <div className={styles['form-item']}>
                      <div className={styles['form-header']}>
                        <p>paddyに改善してほしいポイントを教えてください。</p>
                        <span className={styles['form-label']}>任意</span>
                      </div>
                      <TextareaInput
                        id='kaizen'
                        value={watch('kaizen')}
                        register={register('kaizen')}
                        annotation={'kaizen' in { ...errors } ? errors.kaizen?.message : ''}
                        count={500}
                        isInvalid={'kaizen' in { ...errors }}
                      />
                    </div>
                    <div className={styles['form-item']}>
                      <div className={styles['form-header']}>
                        <p>paddyに欲しかった機能があれば教えてください。</p>
                        <span className={styles['form-label']}>任意</span>
                      </div>
                      <TextareaInput
                        id='function'
                        value={watch('function')}
                        register={register('function')}
                        annotation={'function' in { ...errors } ? errors.function?.message : ''}
                        count={500}
                        isInvalid={'function' in { ...errors }}
                      />
                    </div>
                  </div>

                  <div className={styles['button-wrapper']}>
                    <AsyncButton
                      className={components.button}
                      data-color='black'
                      onClick={handleClickResign}
                      disabled={!formValid}
                      data-disabled={!formValid}
                    >
                      退会する
                    </AsyncButton>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </FullModal>
  );
});
